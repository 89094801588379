import "./home.scss";
import underLine from "../../../assets/icons/under-line-paint.svg";
import residentialServiceImg from "../../../assets/images/residential-service.png";
import { useEffect, useState } from "react";
import hammerIcon from "../../../assets/icons/hammer-icon.svg";
import badgeIcon from "../../../assets/icons/badge-icon.svg";
import peopleIcon from "../../../assets/icons/people-icon.svg";
import timeMoney from "../../../assets/icons/time-money-icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import rightArrow from "../../../assets/icons/right-arrow.svg";
import portfolio1 from "../../../assets/images/port-1.png";
import portfolio3 from "../../../assets/images/port-2.png";
import portfolio2 from "../../../assets/images/port-3.png";
import profileDummy from "../../../assets/images/person.png";
import downAngle from "../../../assets/icons/down-angle.svg";
import { useNavigate } from "react-router-dom";
export default function Home() {
  const navigate = useNavigate();
  const [curService, setService] = useState({
    title: "",
    img: "",
    content: [],
  });
  const portfolioCards = [
    {
      name: "SJP Towers",
      location: "located in Hyderabad",
      img: portfolio1,
    },
    {
      name: "Lorem",
      location: "located in Visakhapatnam",
      img: portfolio2,
    },
    {
      name: "Ipsum",
      location: "located in Bangalore",
      img: portfolio3,
    },
    {
      name: "Ola",
      location: "located in Hyderabad",
      img: portfolio1,
    },
    {
      name: "Amigo",
      location: "located in Visakhapatnam",
      img: portfolio2,
    },
  ];
  const stats = [
    {
      name: "Completed Projects",
      value: "2.5k+",
    },
    {
      name: "Years Experience",
      value: "10+",
    },
    {
      name: "Active Clients",
      value: "1.5k+",
    },
    {
      name: "Team Members",
      value: "100+",
    },
  ];
  const services = [
    {
      title: "Residential",
      img: residentialServiceImg,
      content: [
        `Unleash the potential of your dream home with our expert residential construction services.
        From concept to completion, we bring your unique vision to life with impeccable craftsmanship
        and timely delivery.`,
        `No plot is too small or too big for us to transform into your perfect 
        living space. Say goodbye to compromise and embrace a world of amenities at your doorstep. 
        Build your dream home today and experience the bliss of customized living!`,
      ],
    },
    {
      title: "Commercial",
      img: residentialServiceImg,
      content: [
        `Build your business dreams with us. From offices to retail spaces, 
        we create functional and stunning environments. Timely delivery, 
        cost-effective solutions, and cash compensation for delays. 
        Choose us for commercial construction excellence. 
        Let's make your vision a reality!`,
      ],
    },
    {
      title: "Renovation and Remodeling",
      img: residentialServiceImg,
      content: [
        `Elevate your space. Unleash extraordinary transformations with 
        our renovation and remodeling services. From trendy upgrades to 
        seamless execution, we turn your dream home into a reality. 
        No compromises, no disruptions - just your perfect space 
        brought to life.`,
      ],
    },
    {
      title: "Interior Design",
      img: residentialServiceImg,
      content: [
        `Unleash your style. Inspire with our exceptional interior design services. 
        From vision to reality, we create captivating spaces that reflect your 
        unique personality. Functionality meets aesthetics within your budget. 
        No surprises, just stunning results that exceed expectations. 
        Let's transform your space into an extraordinary experience.`,
      ],
    },
    {
      title: "Project Management",
      img: residentialServiceImg,
      content: [
        `Stress-free construction made simple. 
        Our project management services take the lead, ensuring 
        seamless coordination, timely delivery, and cost-effective 
        solutions.Stay connected to your dream space with mobile updates, 
        360-degree views, and a dedicated project manager 
        just one call away
        `,
        `No more hassles or worries. Experience peace and tranquility 
        as we handle the details, while you enjoy the journey to your 
        perfect space. Trust us to make your dreams come true, stress-free!`,
      ],
    },
    {
      title: "Smart home",
      img: residentialServiceImg,
      content: [
        `Turn your dream home into a reality with our expert
        residential construction services,
        tailored to your unique vision and specifications.`,
        `From concept to completion, our skilled team will 
        handle every aspect of your residential construction 
        project, ensuring quality craftsmanship and timely delivery.`,
      ],
    },
    {
      title: "Architectural Design",
      img: residentialServiceImg,
      content: [
        `Ignite architectural brilliance with us. Functionality meets limitless creativity 
        as our expert architects bring your ideas to life, prioritizing sustainability 
        and efficiency.From initial sketches to precise blueprints, our comprehensive 
        architectural design services ensure your project meets requirements and 
        regulations.`,
        `Embrace world-class architecture tailored to your vision. 
        No plot is too small; our designers create dream spaces that 
        reflect your personality and foster family unity. Step into your 
        sanctuary, where serenity thrives and individuality soars. 
        Join us on an inspiring journey to make your architectural 
        dreams a breathtaking reality.`,
      ],
    },
  ];
  const features = [
    {
      title: "Extensive Experience & Expertise",
      icon: hammerIcon,
      desc: "Our company brings years of experience and expertise to the table. We have successfully completed a diverse range of construction projects, including residential, commercial, and industrial developments.",
    },
    {
      title: "Commitment to quality",
      icon: badgeIcon,
      desc: "We are dedicated to delivering construction projects of the highest standard, meeting and exceeding client expectations. Our meticulous attention to detail ensures that every aspect of the project, from planning to execution, is carried out with precision and excellence.",
    },
    {
      title: "Client-Centric Approach:",
      icon: peopleIcon,
      desc: "We value your input and strive to understand your unique requirements, preferences, and budget constraints. Our team works tirelessly to provide personalized solutions tailored to your specific needs.",
    },
    {
      title: "Timely & Cost Effective Execution",
      icon: timeMoney,
      desc: "We understand that time and budget are critical factors in any construction project. Our company is committed to delivering projects on schedule and within the agreed-upon budget.",
    },
  ];
  const clients = [
    {
      img: profileDummy,
      name: "Dileep",
      designation: "CEO, Dileep Associates",
      review:
        "Amit and team have helped us with our IT service requirements. Thanks to the Get Target IT team, I can now focus on my business growth, instead of worrying about IT issues.",
    },
    {
      img: profileDummy,
      name: "Sajith Kumar",
      designation: "Founder, Skipprz",
      review:
        "Thank you Get Target IT for your expertise and pan-India services. Now we have a reliable partner to lean on for our IT-related requirements!",
    },
    {
      img: profileDummy,
      name: "Arvind Saxena",
      designation: "Ubona Technologies",
      review:
        "Get Target IT team helped us scale with their pan-India services, while increasing our customer satisfaction index. They are reliable, cost effective and offer the shortest TAT.",
    },
    {
      img: profileDummy,
      name: "Nagraj Katti",
      designation: "CEO, Industrial Accessories",
      review:
        "We were able to mitigate various IT issues, thanks to their expert guidance and deep-rooted inputs. This has helped us save a significant amount of time.",
    },
    {
      img: profileDummy,
      name: "Sajith Kumar",
      designation: "Founder, Skipprz",
      review:
        "Thank you Get Target IT for your expertise and pan-India services. Now we have a reliable partner to lean on for our IT-related requirements!",
    },
    {
      img: profileDummy,
      name: "Arvind Saxena",
      designation: "Ubona Technologies",
      review:
        "Get Target IT team helped us scale with their pan-India services, while increasing our customer satisfaction index. They are reliable, cost effective and offer the shortest TAT.",
    },
  ];
  const openSelect = ()=>{
    const dropDown = document.querySelector('.drop-down');
    if(dropDown.classList.contains('active'))
      dropDown.classList.remove('active');
    else  
      dropDown.classList.add('active');
  }
  const closeSelect = ()=>{
    const dropDown = document.querySelector('.drop-down');
    if(dropDown.classList.contains('active'))
      dropDown.classList.remove('active');
  }
  useEffect(() => {
    setService(services[0]);
  }, []);
  return (
    <div className="home">
      <div className="header">
        <div className="container">
          <h1 className="title">
            We build your <br /> dream house
          </h1>
          <p className="tagline">
            <span>India’s No.1</span> Tech Powered <br /> Construction Company
          </p>
          <button
            className="get-quote"
            onClick={() => {
              navigate("/quote");
            }}
          >
            Get a quote
          </button>
        </div>
      </div>
      <div className="services">
        <div className="title">
          <h1>Buildgenix Services</h1>
          <img src={underLine} alt="" />
        </div>
        <div className="container">
          <div className="service-head">  
            <ul className="service-head-btns">
              <div className="service-select" onClick={openSelect}>
                <p>{curService.title}</p>
                <span><img src={downAngle} alt="" /></span>
              </div>
              <div className="drop-down">
              {services.map((service, i) => {
                return (
                  <li
                    className={`service-head-btn ${
                      curService.title === service.title ? "active" : ""
                    }`}
                    onClick={() => {
                      setService(service);
                      closeSelect();
                    }}
                    key={`service-${i}`}
                  >
                    {service.title}
                  </li>
                );
              })}
              </div>
            </ul>
          </div>
          <div className="service-body">
            <div className="col">
              <img src={curService.img} alt="" />
            </div>
            <div className="col">
              <div className="title">
                <h3>{curService.title}</h3>
                <img src={underLine} alt="" />
              </div>
              <div className="content">
                {curService.content.map((data, i) => {
                  return <p key={`data-${i}`} >{data}</p>;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="features">
        <div className="title">
          <h1>Why Buildgenix?</h1>
          <img src={underLine} alt="" />
        </div>
        <div className="tag-line">
          <p>
            Building a solid foundation of trust with{" "}
            <span>10+ years of expertise</span>
          </p>
        </div>
        <div className="body">
          {features.map((feature, i) => {
            return (
              <div className="feature-card" key={`feature-card${i}`}>
                <div className="icon">
                  <img src={feature.icon} alt="" />
                </div>
                <div className="data">
                  <div className="title">
                    <h3>{feature.title}</h3>
                  </div>
                  <div className="content">
                    <p>{feature.desc}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="stats">
        <div className="container">
          {stats.map((stat, i) => {
            return (
              <div className="stat">
                <div className="stat-value">
                  <h1>{stat.value}</h1>
                </div>
                <div className="stat-name">
                  <p>{stat.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
      <div className="portfolio">
        <div className="title">
          <h1>Our Portfolio</h1>
          <img src={underLine} alt="" />
        </div>
        <div className="body">
          <Swiper
            navigation={false}
            pagination={true}
            modules={[Navigation, Pagination]}
            className="mySwiper"
            slidesPerView={1}
            breakpoints={{
              768: {
                slidesPerView: 3,
              },
            }}
          >
            {portfolioCards.map((rec, i) => {
              return (
                <SwiperSlide key={`slide-${i}`}>
                  <div className="portfolio-card">
                    <div className="card-img">
                      <img src={rec.img} alt="" />
                    </div>
                    <div className="content">
                      <div>
                        <p className="name">{rec.name}</p>
                        <p className="location">{rec.location}</p>
                      </div>
                      <div className="link">
                        <a href="/">
                          View Detail <img src={rightArrow} alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      {/* <div className="clients">
        <div className="title">
          <h1>Buildgenix's Beloved Clients</h1>
          <img src={underLine} alt="" />
        </div>
        <div className="body">
          {clients.map((client, i) => {
            return (
              <div className="client-card">
                <div className="client-head">
                  <div className="profile-img">
                    <img src={client.img} alt="" />
                  </div>
                  <div className="detail">
                    <p className="name">{client.name}</p>
                    <p className="designation">{client.designation}</p>
                  </div>
                </div>
                <div className="client-body">
                  <p className="desc">{client.review}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
}
