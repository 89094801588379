export default function Services(){
    return(
        <div className="services">
            <div className="title">
                <h1>
                    Residential Construction
                </h1>
            </div>
            
        </div>
    )
}