import { useState } from "react";
import "./cost.scss";
import { TextField } from "@mui/material";
export default function Cost() {
  const [estimateShow, setEstimateShow] = useState(false);
  const [formError, setFormError] = useState(null);
  const [estData, setEstData] = useState({
    pincode: 0,
    noOfParking: 0,
    balconyUitlty: 0,
    estimate: 0,
    builtupArea: 0,
  });
  const packages = [
    {
      packageName: "Elite",
      cost: 1710,
    },
    {
      packageName: "Package 2",
      cost: 2010,
    },
    {
      packageName: "Package 3",
      cost: 2710,
    },
    {
      packageName: "Package 4",
      cost: 3010,
    },
  ];
  const changeEstData = (e) => {
    var value = e.target.value;
    if(e.target.name !== "pincode")
      value = e.target.value || 0;
    setEstData({
      ...estData,
      [e.target.name]: value,
    });
  };
  const projectCard = (e) => {
    if (window.innerWidth <= 768) return;
    console.log(e.target);
    const cards = document.querySelectorAll(".estimate-card");
    cards.forEach((card) => {
      if (card !== e.target && !card.contains(e.target)) {
        card.classList.add("inactive");
      }
    });
  };
  const resetCard = () => { 
    if (window.innerWidth <= 768) return;
    const cards = document.querySelectorAll(".estimate-card");
    cards.forEach((card) => {
      card.classList.remove("inactive");
    });
  };
  const getCost = () => {
    if (estData.builtupArea === 0) {
      setFormError("*Enter builtup area");
      return;
    }
    //validate
    setFormError(null);
    setEstimateShow(true);
    const totalEstimate =
      parseFloat(estData.builtupArea) * 2260 +
      parseFloat(estData.balconyUitlty) * 2260 * 0.7 +
      parseInt(estData.noOfParking) * 120 * 2260 * 0.7;
    setEstData({
      ...estData,
      estimate: totalEstimate,
    });
    return 0;
  };
  return (
    <div className="cost-estimator">
      <div className="container">
        <div className="title">
          <h1>House Construction Cost Calculator</h1>
          <p className="desc">
            Get in touch to get an estimate of house construction costs. Speak
            to our technical expert for a more accurate pricing.
          </p>
        </div>
        <div className="body">
          <div className="field-container">
            <p>Pin Code *:</p>
            <TextField
              required
              name="pincode"
              value={estData.pincode}
              InputProps={{ inputProps: { min: 0 } }}
              type="number"
              size="small"
              onChange={changeEstData}
            />
          </div>
          <div className="field-container">
            <p>Super Builtup Area(sq.ft):</p>
            <TextField
              required
              value={estData.builtupArea}
              name="builtupArea"
              InputProps={{ inputProps: { min: 0 } }}
              type="number"
              size="small"
              onChange={changeEstData}
            />
          </div>
          <div className="field-container">
            <p>No.of Car Parking(120sq.ft/unit)*:</p>
            <TextField
              required
              name="noOfParking"
              value={estData.noOfParking}
              InputProps={{ inputProps: { min: 0 } }}
              type="number"
              size="small"
              onChange={changeEstData}
            />
          </div>
          <div className="field-container">
            <p>Balcony & Utility Area(sq.ft)*:</p>
            <TextField
              required
              name="balconyUitlty"
              value={estData.balconyUitlty}
              InputProps={{ inputProps: { min: 0 } }}
              type="number"
              size="small"
              onChange={changeEstData}
            />
          </div>

          <div className="controls">
            <p className="error">{formError}</p>
            <div className="submit-quote">
              <button
                onClick={() => {
                  getCost();
                }}
              >
                Estimate Cost
              </button>
            </div>
          </div>
        </div>
        {estimateShow && (
          <div className="estimate-cards">
            {packages.map((rec) => {
              return (
                <div
                  className="estimate-card"
                  onMouseOver={projectCard}
                  onMouseLeave={resetCard}
                  key={JSON.stringify(rec)}
                >
                  <div className="head">
                    <p>
                      {rec.packageName} - &#8377;{rec.cost}/sq.ft (incl. GST)
                    </p>
                  </div>
                  <div className="card-body">
                    <div className="cost-row">
                      <div className="desc">
                        <p className="name">Builtup Cost</p>
                        <p className="dimensions">
                          {estData.builtupArea} sq.ft * &#8377;
                          {rec.cost}/sq.ft
                        </p>
                      </div>
                      <div className="cost">
                        <p>
                          &#8377;{" "}
                          {(estData.builtupArea * rec.cost).toLocaleString(
                            "en-IN"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="cost-row">
                      <div className="desc">
                        <p className="name">Car Parking Cost</p>
                        <p className="dimensions">
                          {estData.noOfParking} * 120 sq.ft * &#8377;
                          {(rec.cost * 0.7).toFixed(2)}/sq.ft
                        </p>
                      </div>
                      <div className="cost">
                        <p>
                          &#8377;{" "}
                          {(
                            estData.noOfParking *
                            120 *
                            rec.cost *
                            0.7
                          ).toLocaleString("en-IN")}
                        </p>
                      </div>
                    </div>
                    <div className="cost-row">
                      <div className="desc">
                        <p className="name">Balcony & Utility Cost</p>
                        <p className="dimensions">
                          {estData.balconyUitlty} sq.ft * &#8377;
                          {(rec.cost * 0.7).toFixed(2)}/sq.ft
                        </p>
                      </div>
                      <div className="cost">
                        <p>
                          &#8377;{" "}
                          {(
                            estData.balconyUitlty *
                            rec.cost *
                            0.7
                          ).toLocaleString("en-IN")}
                        </p>
                      </div>
                    </div>
                    <div className="cost-row total">
                      <div className="desc">
                        <p className="name">Total Cost</p>
                      </div>
                      <div className="cost">
                          <p>
                            &#8377;{" "}
                            {(
                              estData.balconyUitlty * rec.cost * 0.7 +
                              estData.noOfParking * 120 * rec.cost * 0.7 +
                              estData.builtupArea * rec.cost
                            ).toLocaleString("en-IN")}
                          </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
