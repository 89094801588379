import './blogPage.scss';
import { useLocation } from 'react-router-dom';
import blogImage from '../../../assets/images/blog-images/blog-main-1.png';
export default function BlogPage(){
    const location = useLocation();
    const blogData = location.state;
    return(
        <div className="blog-page">
            <div className="blog-image">
                <img src={blogImage} alt="" />
            </div>
            <div className="title">
                <h1>
                    {
                        blogData.title
                    }
                </h1>
            </div>
            <div className="desc">
                    {
                        blogData.content.map((text,i)=>{
                            return(
                                <p key={`content-${i}`}>
                                    {text}
                                </p>
                            )
                        })
                    }
            </div>
        </div>
    )
}