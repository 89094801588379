import "./faq.scss";
import underlineImage from "../../../assets/icons/under-line-paint.svg";
import { useState } from "react";

export default function Faq() {
  const faqs = [
    {
      question:
        "Will Buildgenix packages include necessary approvals if required?",
      answer:
        "Buildgenix assists clients throughout the approval process by connecting them with Construction Professionals on our platform. Although we are not directly involved in the approval process, our partners ensure a hassle-free experience for our clients.",
    },
    {
      question: "What quality checks do you conduct on-site? ",
      answer:
        "We have developed our own comprehensive QA Checklist, consisting of over 440 quality checks. Our Project management team ensures all respective checks are done before proceeding to the next stage.  ",
    },
    {
      question: "Does the package include a compound wall? ",
      answer:
        "Not all houses require a compound wall, and some may only need it on a few sides. By excluding the compound wall from the package, we ensure that clients who opt for a compound wall are the ones paying for it.",
    },
    {
      question:
        "Can I choose different brands of materials instead of the ones provided in the packages? ",
      answer:
        "Our packages are designed in a modular manner, allowing for flexibility. Making changes to the specifications is as simple as adding or removing items from your shopping cart. Our technical consultants can demonstrate this to you.",
    },
    {
      question: "How do you assign contractors to projects?",
      answer:
        "Buildgenix conducts a series of background checks on contractors before they can work with us. These checks include verifying documents such as GST registrations and income tax filings. Our team also physically verifies ongoing and completed projects by the contractor. We continuously assess and rate contractors to ensure the best ones are assigned more projects.",
    },
    {
      question:
        "How long does it typically take to complete the construction of one floor?",
      answer:
        "The construction of the first floor usually takes about 4-5 months from scratch including the foundation, and each additional floor requires approximately 1.5 months. The duration can vary based on factors such as the built-up area, location, and design complexity.",
    },
    {
      question: "Does Buildgenix provide loans? ",
      answer:
        "Buildgenix assists clients with the documentation process for loan approvals. We have partnered with several banks and NBFCs to offer attractive interest rates.",
    },
    {
      question:
        "Does Buildgenix offer complete packages that include interior works?",
      answer:
        " If you wish to get the interiors done through Buildgenix, we have separate packages for interior works. We have also collaborated with boutique designer firms to provide competitive prices for amazing interiors.",
    },
    {
      question:
        "What happens if material prices increase during the project? Is there a price escalation?",
      answer:
        "Our contracts are based on fixed pricing mentioned on a stamp paper, ensuring stability in most scenarios. However, in exceptional situations where there is a sudden spike in prices due to external factors, the price variation may need to be paid by the client. Conversely, if there is a price drop, clients benefit from a reduced price.",
    },
    {
      question:
        "What are the key factors influencing the cost of house construction?",
      answer:
        "The construction cost of a house primarily depends on factors such as the super built-up area, open area (balcony, utility), quality of construction materials, and interiors. Buildgenix offers various packages at different prices to suit your specific needs.",
    },
    {
      question:
        "What are the definitions of super built-up area, built-up area, and carpet area?",
      answer:
        "The carpet area refers to the usable area inside the house that can be covered by carpet. It excludes balconies and utility areas but includes the space covered by internal walls. The built-up area is the sum of the carpet area and the area covered by balconies, terraces, and other utilities. The super built-up area encompasses the built-up area as well as common areas like lobbies, staircases, elevators, shafts, and clubhouses. This is often referred to as the saleable area.",
    },
  ];
  const [curQuestion,setQuestion] = useState(null);
  return (
    <div className="faq-main">
      <div className="title">
        <h1>FAQs</h1>
        <img src={underlineImage} alt="" />
      </div>
      <div className="body">
        {faqs.map((rec, i) => {
          return (
            <div className={`faq ${curQuestion === i ? 'active' : ''}`}>
              <div className="question" onClick={()=>{
                if(curQuestion === i)
                    setQuestion(null)
                else
                    setQuestion(i);
              }}>
                <h3>{rec.question}</h3>
                {
                    curQuestion === i ? <h3>-</h3> : <h3>+</h3>
                }
              </div>
              <div className="answer">
                <p>{rec.answer}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
