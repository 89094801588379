import "./quotegen.scss";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import underLine from "../../../assets/icons/under-line-paint.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function QuoteGenerator() {
  const navigate = useNavigate();
  // available packages

  const packages = ["Elite"];
  const floorUnitTypes = [
    "1BHK",
    "2BHK",
    "3BHK",
    "Balcony",
    "Kitchen",
    "Utility",
    "Open Area",
    "Parking",
  ];
  // available duplex floor options
  const [duplexFloors, setDupleFloors] = useState([]);
  // Quotaion data object [main]
  const generateDuplexFloors = (floorCount) => {
    var temp = [];
    if (floorCount > 0) {
      for (var i = 0; i < floorCount; i++) {
        var str = `[${i}&${i + 1}]`;
        temp.push(str);
      }
    }
    console.log(temp)
    setDupleFloors(temp);
  };
  const [quoteData, setQuoteData] = useState({
    length: 0,
    breadth: 0,
    height: 0,
    roads: {
      count: 1,
      units: [
        {
          side: "length",
          value: 0,
        },
      ],
    },
    builtupArea: 0,
    builtupAreaFloor: 0,
    perFloorBuiltUpArea: 0,
    minimumSetBack: 0,
    floorCount: 1,
    needBasement: false,
    duplex: {
      required: false,
      duplexUnits: ["0&1"],
      unitsData: [],
    },
    compoundWall: {
      required: false,
      type: "default",
      runningFeet: 0,
      mainGateDimensions: {
        length: 0,
        height: 0,
      },
      extraGateDimensions: {
        length: 0,
        height: 0,
      },
    },
    packages: {
      samePackage: false,
      selectedPackage: packages[0],
      packageRate: 2260,
    },
    floors: [
      {
        floorNumber: "Floor 0",
        units: [
          {
            type: "2BHK",
            numberOfUnits: 1,
            floorArea: 0,
            packageType: "Elite",
            description: "",
          },
        ],
      },
    ],
  });
  // add a new floor unit inside a floor [takes floor number]
  const addFloorUnit = (floorNo) => {
    console.log(floorNo);
    var floors = quoteData.floors;
    console.log(floors[floorNo]);
    floors[floorNo] = {
      ...floors[floorNo],
      units: [
        ...floors[floorNo].units,
        {
          type: "2BHK",
          numberOfUnits: 1,
          floorArea: 0,
          packageType: quoteData.packages.selectedPackage,
          description: "",
        },
      ],
    };
    setQuoteData({
      ...quoteData,
      floors,
    });
  };
  //remove a floor unit for a floor [takes floor number and unit number]
  const removeFloorUnit = (floorNo, unitNo) => {
    var floors = quoteData.floors;
    var units = floors[floorNo].units;
    if (units.length === 1) return;
    units.splice(unitNo, 1);
    floors[floorNo] = {
      ...floors[floorNo],
      units,
    };
    setQuoteData({
      ...quoteData,
      floors,
    });
  };
  // genrates suffix for a number [takes number as argument]
  const getFloorSuffix = (i) => {
    const suffixes = ["st", "nd", "rd"];
    if (i <= 3) {
      return suffixes[i - 1];
    }
    return "th";
  };

  // sends quotation data to edit quote page
  const generateQuotation = () => {
    // validate required fields
    navigate("/generate-quote/edit", { state: quoteData });
  };
  return (
    <div className="quote-gen">
      <div className="main-title">
        <h1>Quotation</h1>
        <img src={underLine} alt="" />
      </div>
      <div className="container">
        <div className="section">
          <div className="title">
            <h2>Plot Details</h2>
          </div>
          <div className="body">
            <div className="field-container">
              <p>Length ( ft ):</p>
              <TextField
                required
                id="plot-length"
                type="number"
                size="small"
                value={quoteData.length}
                onChange={(e) => {
                  var builtupArea =
                    (parseFloat(e.target.value) || 0) * quoteData.breadth;
                  builtupArea.toFixed(2);
                  setQuoteData({
                    ...quoteData,
                    length: parseFloat(e.target.value) || 0,
                    builtupArea:
                      (parseFloat(e.target.value) || 0) * quoteData.breadth,
                    minimumSetBack: (builtupArea * 0.33).toFixed(2),
                    builtupAreaFloor: (builtupArea * 0.67).toFixed(2),
                  });
                }}
              />
            </div>
            <div className="field-container">
              <p>Breadth (ft):</p>
              <TextField
                required
                id="plot-breadth"
                value={quoteData.breadth}
                onChange={(e) => {
                  var builtupArea =
                    (parseFloat(e.target.value) || 0) * quoteData.length;
                  builtupArea.toFixed(2);
                  setQuoteData({
                    ...quoteData,
                    breadth: parseFloat(e.target.value) || 0,
                    builtupArea:
                      (parseFloat(e.target.value) || 0) * quoteData.length,
                    minimumSetBack: (builtupArea * 0.33).toFixed(2),
                    builtupAreaFloor: (builtupArea * 0.67).toFixed(2),
                  });
                }}
                type="number"
                size="small"
              />
            </div>
            <div className="field-container">
              <p>Height (ft):</p>
              <TextField
                required
                id="plot-height"
                value={quoteData.height}
                onChange={(e) => {
                  setQuoteData({
                    ...quoteData,
                    height: parseFloat(e.target.value) || 0,
                  });
                }}
                type="number"
                size="small"
              />
            </div>
            <div className="field-container">
              <p>No.of Roads (surrounding):</p>
              <TextField
                required
                id="plot-roads"
                value={quoteData.roads.count}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => {
                  var roadCount = parseInt(e.target.value);
                  var roads = [];
                  for (var i = 0; i < roadCount; i++)
                    roads.push({
                      side: "length",
                      value: 0,
                    });
                  console.log(roads);
                  setQuoteData({
                    ...quoteData,
                    roads: {
                      count: roadCount,
                      units: roads,
                    },
                  });
                }}
                type="number"
                size="small"
              />
            </div>
            {quoteData.roads.units.map((unit, i) => {
              return (
                <>
                  <div className="field-container">
                    <p>Road no. {i + 1}:</p>
                    <TextField
                      required
                      id="plot-road-no"
                      type="number"
                      size="small"
                      value={unit.value}
                      onChange={(e) => {
                        var roadUnits = quoteData.roads.units;
                        roadUnits[i] = {
                          ...roadUnits[i],
                          value: parseFloat(e.target.value) || 0,
                        };
                        setQuoteData({
                          ...quoteData,
                          roads: {
                            ...quoteData.roads,
                            units: roadUnits,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="field-container road-dimension">
                    <p>Road no. {i + 1} - Side:</p>
                    <FormControl>
                      <Select
                        labelId="road-side-label"
                        id="road-side"
                        value={unit.side}
                        size="small"
                        onChange={(e) => {
                          var roadUnits = quoteData.roads.units;
                          roadUnits[i] = {
                            ...roadUnits[i],
                            side: e.target.value,
                          };
                          setQuoteData({
                            ...quoteData,
                            roads: {
                              ...quoteData.roads,
                              units: roadUnits,
                            },
                          });
                        }}
                      >
                        <MenuItem value={"length"}>Length</MenuItem>
                        <MenuItem value={"breadth"}>Breadth</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </>
              );
            })}
            {/* <div className="field-container">
              <button>Submit</button>
            </div> */}
            <div className="field-container">
              <p>BuiltUp Area (sft):</p>
              <TextField
                required
                id="plot-builtup-area"
                value={quoteData.builtupArea}
                type="text"
                disabled
                size="small"
              />
            </div>
            <div className="field-container">
              <p>BuiltUp Area Floor (sft):</p>
              <TextField
                required
                id="plot-builtup-area"
                value={quoteData.builtupAreaFloor}
                type="text"
                disabled
                size="small"
              />
            </div>
            <div className="field-container">
              <p>Per Floor BuiltUp Area (sft):</p>
              <TextField
                required
                id="plot-builtup-area-floor"
                value={quoteData.perFloorBuiltUpArea}
                onChange={(e) => {
                  setQuoteData({
                    ...quoteData,
                    perFloorBuiltUpArea: parseFloat(e.target.value) || 0,
                  });
                }}
                type="number"
                size="small"
              />
            </div>
            {/* <div className="field-container validation">
              <button>validate</button>
            </div> */}
            <div className="field-container">
              <p>Minimum Setack (sft):</p>
              <TextField
                required
                id="plot-min-setback"
                value={quoteData.minimumSetBack}
                type="number"
                size="small"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Floors</h2>
          </div>
          <div className="body">
            <div className="field-container">
              <p>No. of floors (excluding basement):</p>
              <TextField
                required
                id="plot-min-setback"
                value={quoteData.floorCount}
                onChange={(e) => {
                  var floorCount = parseInt(e.target.value);
                  var floors = [];
                  for (var i = 0; i < floorCount; i++) {
                    floors.push({
                      floorNumber: `Floor ${i}`,
                      units: [
                        {
                          type: floorUnitTypes[0],
                          numberOfUnits: 1,
                          floorArea: 0,
                          packageType: packages[0],
                          description: "",
                        },
                      ],
                    });
                  }
                  setQuoteData({
                    ...quoteData,
                    floors: floors,
                    floorCount: floorCount,
                  });
                  generateDuplexFloors(floorCount);
                }}
                type="number"
                size="small"
                InputProps={{ inputProps: { min: 1 } }}
              />
            </div>
            <div className="field-container">
              <p>Need basement:</p>
              <Checkbox
                value={quoteData.needBasement}
                onChange={(e) => {
                  setQuoteData({
                    ...quoteData,
                    needBasement: e.target.checked,
                  });
                }}
                id="need-basement"
              />
            </div>
            <div className="field-container">
              <p>Need duplex:</p>
              <Checkbox
                id="need-duplex"
                value={quoteData.duplex.required}
                onChange={(e) => {
                  setQuoteData({
                    ...quoteData,
                    duplex: {
                      ...quoteData.duplex,
                      required: e.target.checked,
                      unitsData: [],
                    },
                  });
                }}
              />
              {quoteData.duplex.required && (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={duplexFloors}
                  onChange={(e, values) => {
                    console.log(values);
                    var unitsData = [];
                    values.forEach((value) => {
                      unitsData.push({
                        type: value,
                        area: 0,
                      });
                    });
                    setQuoteData({
                      ...quoteData,
                      duplex: {
                        ...quoteData.duplex,
                        duplexUnits: values,
                        unitsData,
                      },
                    });
                  }}
                  multiple={true}
                  renderInput={(params) => (
                    <TextField {...params} name="floors data" />
                  )}
                />
              )}
            </div>
            {quoteData.duplex.required &&
              quoteData.duplex.unitsData.map((rec, i) => {
                return (
                  <div className="field-container">
                    <p>Head Room Area of {rec.type} (sft):</p>
                    <TextField
                      required
                      id="plot-head-room-area"
                      defaultValue="0"
                      type="number"
                      size="small"
                      onChange={(e) => {
                        var value = parseInt(e.target.value);
                        var duplexUnits = quoteData.duplex.unitsData;
                        duplexUnits[i] = {
                          type: rec.type,
                          area: value,
                        };
                        setQuoteData({
                          ...quoteData,
                          duplex: {
                            ...quoteData.duplex,
                            unitsData: duplexUnits,
                          },
                        });
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Compound Wall:</h2>
          </div>
          <div className="body">
            <div className="field-container">
              <p>Need compound wall:</p>
              <Checkbox
                id="need-compound-wall"
                onChange={(e) => {
                  setQuoteData({
                    ...quoteData,
                    compoundWall: {
                      ...quoteData.compoundWall,
                      required: e.target.checked,
                    },
                  });
                }}
              />
            </div>
            {quoteData.compoundWall.required && (
              <>
                <div className="field-container">
                  <p>Compound wall:</p>
                  <FormControl>
                    <Select
                      labelId="road-side-label"
                      id="road-side"
                      value={quoteData.compoundWall.type}
                      onChange={(e) => {
                        setQuoteData({
                          ...quoteData,
                          compoundWall: {
                            ...quoteData.compoundWall,
                            type: e.target.value,
                          },
                        });
                      }}
                      //   label="Side"
                      size="small"
                    >
                      <MenuItem value={"customized"}>Customized</MenuItem>
                      <MenuItem value={"default"}>Default</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {quoteData.compoundWall.type === "customized" ? (
                  <>
                    <div className="field-container">
                      <p>Running Feet (rft):</p>
                      <TextField
                        required
                        id="plot-compound-wall-running-feet"
                        value={quoteData.compoundWall.runningFeet}
                        onChange={(e) => {
                          setQuoteData({
                            ...quoteData,
                            compoundWall: {
                              ...quoteData.compoundWall,
                              runningFeet: parseFloat(e.target.value) || 0,
                            },
                          });
                        }}
                        type="number"
                        size="small"
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="field-container">
                  <p>Main Gate:[length X height] </p>
                  <div className="input-group">
                    <TextField
                      required
                      id="plot-compound-wall-main-gate"
                      value={quoteData.compoundWall.mainGateDimensions.length}
                      onChange={(e) => {
                        setQuoteData({
                          ...quoteData,
                          compoundWall: {
                            ...quoteData.compoundWall,
                            mainGateDimensions: {
                              ...quoteData.compoundWall.mainGateDimensions,
                              length: parseFloat(e.target.value) || 0,
                            },
                          },
                        });
                      }}
                      sx={{ width: 70 }}
                      type="number"
                      size="small"
                    />
                    <p> X </p>
                    <TextField
                      required
                      id="plot-compound-wall-main-gate"
                      value={quoteData.compoundWall.mainGateDimensions.height}
                      onChange={(e) => {
                        setQuoteData({
                          ...quoteData,
                          compoundWall: {
                            ...quoteData.compoundWall,
                            mainGateDimensions: {
                              ...quoteData.compoundWall.mainGateDimensions,
                              height: parseFloat(e.target.value) || 0,
                            },
                          },
                        });
                      }}
                      sx={{ width: 70 }}
                      type="number"
                      size="small"
                    />
                  </div>
                </div>
                <div className="field-container">
                  <p>Extra Gate :[length X height]</p>
                  <div className="input-group">
                    <TextField
                      required
                      id="plot-compound-wall-extra-gate"
                      value={quoteData.compoundWall.extraGateDimensions.length}
                      onChange={(e) => {
                        setQuoteData({
                          ...quoteData,
                          compoundWall: {
                            ...quoteData.compoundWall,
                            extraGateDimensions: {
                              ...quoteData.compoundWall.extraGateDimensions,
                              length: parseFloat(e.target.value) || 0,
                            },
                          },
                        });
                      }}
                      sx={{ width: 70 }}
                      type="number"
                      size="small"
                    />
                    <p>X</p>
                    <TextField
                      required
                      id="plot-compound-wall-extra-gate"
                      value={quoteData.compoundWall.extraGateDimensions.height}
                      onChange={(e) => {
                        setQuoteData({
                          ...quoteData,
                          compoundWall: {
                            ...quoteData.compoundWall,
                            extraGateDimensions: {
                              ...quoteData.compoundWall.extraGateDimensions,
                              height: parseFloat(e.target.value) || 0,
                            },
                          },
                        });
                      }}
                      sx={{ width: 70 }}
                      type="number"
                      size="small"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Packages</h2>
          </div>
          <div className="body">
            <div className="field-container">
              <p>Same package for all floors:</p>
              <Checkbox
                id="need-compound-wall"
                checked={quoteData.packages.samePackage}
                onChange={(e) => {
                  console.log(e.target.checked);
                  setQuoteData({
                    ...quoteData,
                    packages: {
                      ...quoteData.packages,
                      samePackage: e.target.checked,
                    },
                  });
                }}
              />
            </div>
            <div className="field-container">
              <p>Package:</p>
              <FormControl>
                <Select
                  labelId="road-side-label"
                  id="road-side"
                  value={quoteData.packages.selectedPackage}
                  onChange={(e) => {
                    setQuoteData({
                      ...quoteData,
                      packages: {
                        ...quoteData.packages,
                        selectedPackage: e.target.value,
                      },
                    });
                  }}
                  //   label="Side"
                  size="small"
                >
                  {packages.map((rec, i) => {
                    return (
                      <MenuItem key={`package-${i}`} value={rec}>
                        {rec}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="floors">
          {quoteData.floors.map((rec, i) => {
            return (
              <div className="floor" key={`floor ${i}`}>
                <div className="title">
                  <h2>Floor {i}</h2>
                </div>
                {rec.units.map((unit, j) => {
                  return (
                    <div
                      className="section floor-unit"
                      key={`floor-${i}; unit - ${j}`}
                    >
                      <div className="body">
                        <div className="field-container">
                          <p>Type of units:</p>
                          <FormControl>
                            <Select
                              labelId="road-side-label"
                              id="road-side"
                              value={unit.type}
                              onChange={(e) => {
                                var floors = quoteData.floors;
                                var units = floors[i].units;
                                units[j] = {
                                  ...units[j],
                                  type: e.target.value,
                                };
                                floors[i].units = units;
                                setQuoteData({
                                  ...quoteData,
                                  floors,
                                });
                              }}
                              size="small"
                            >
                              {floorUnitTypes.map((rec, i) => {
                                return (
                                  <MenuItem
                                    value={rec}
                                    key={`floor-unit-type-${i}`}
                                  >
                                    {rec}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="field-container">
                          <p>Number of units</p>
                          <TextField
                            required
                            id="plot-floor-units"
                            onChange={(e) => {
                              var floors = quoteData.floors;
                              var units = floors[i].units;
                              units[j] = {
                                ...units[j],
                                numberOfUnits: parseInt(e.target.value),
                              };
                              floors[i].units = units;
                              setQuoteData({
                                ...quoteData,
                                floors,
                              });
                            }}
                            type="number"
                            size="small"
                          />
                        </div>
                        <div className="field-container">
                          <p>Floor Area (sft):</p>
                          <TextField
                            required
                            id="plot-floor-units"
                            onChange={(e) => {
                              var floors = quoteData.floors;
                              var units = floors[i].units;
                              units[j] = {
                                ...units[j],
                                floorArea: parseFloat(e.target.value) || 0,
                              };
                              floors[i].units = units;
                              setQuoteData({
                                ...quoteData,
                                floors,
                              });
                            }}
                            type="number"
                            size="small"
                          />
                        </div>
                        <div className="field-container">
                          <p>Type of package:</p>
                          <FormControl>
                            <Select
                              disabled={quoteData.packages.samePackage}
                              labelId="road-floor-unit-package"
                              id="road-side"
                              value={unit.packageType}
                              onChange={(e) => {
                                var floors = quoteData.floors;
                                var units = floors[i].units;
                                units[j] = {
                                  ...units[j],
                                  packageType: e.target.value,
                                };
                                floors[i].units = units;
                                setQuoteData({
                                  ...quoteData,
                                  floors,
                                });
                              }}
                              size="small"
                            >
                              {packages.map((rec, i) => {
                                return <MenuItem value={rec}>{rec}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="field-container">
                          <p>Floor Description:</p>
                          <TextField
                            required
                            id="plot-floor-description"
                            value={unit.description}
                            onChange={(e) => {
                              var floors = quoteData.floors;
                              var units = floors[i].units;
                              units[j] = {
                                ...units[j],
                                description: e.target.value,
                              };
                              floors[i].units = units;
                              setQuoteData({
                                ...quoteData,
                                floors,
                              });
                            }}
                            type="text"
                            size="small"
                          />
                        </div>
                        <div className="field-container">
                          <button
                            className="remove"
                            onClick={() => {
                              removeFloorUnit(i, j);
                            }}
                          >
                            - remove floor unit
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="control">
                  <button
                    className="add"
                    onClick={() => {
                      addFloorUnit(i);
                    }}
                  >
                    + add floor unit
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="controls submit">
        <button onClick={generateQuotation}>Generate Quote</button>
      </div>
    </div>
  );
}
