import "./footer.scss";
import { Link } from "react-router-dom";
import mailIcon from "../../assets/icons/mail-icon.svg";
import mapIcon from "../../assets/icons/map-icon.svg";
import callIcon from "../../assets/icons/call-icon.svg";
import twIcon from "../../assets/icons/tw.svg";
import inIcon from "../../assets/icons/in.svg";
import fbIcon from "../../assets/icons/fb.svg";
import igIcon from "../../assets/icons/ig.svg";

export default function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="news-letter">
          <div className="title">
            <h1>Subscribe for more updates from BuildGenix</h1>
          </div>
          <div className="form">
            <div className="col field-container">
              <input type="email" placeholder="Enter your e-mail..." />
            </div>
            <div className="col field-container">
              <button>Subscribe</button>
            </div>
          </div>
        </div>
        <div className="contact-info">
          <div className="col">
            <div className="icon">
              <img src={mailIcon} alt="" />
            </div>
            <p className="mail contact-detail">
              <a href="mailto:info@buildgneix.in">info@buildgneix.in</a>
            </p>
          </div>
          <div className="col">
            <div className="icon">
              <img src={mapIcon} alt="" />
            </div>
            <p className="address contact-detail">
              3584, MIG-2, BHEL,Hyderabad-502032
            </p>
          </div>
          <div className="col">
            <div className="icon">
              <img src={callIcon} alt="" />
            </div>
            <p className="phone contact-detail">
              <a href="tel:+911234567890">+91 88010 36359</a>
            </p>
            <p className="phone contact-detail">
              <a href="tel:+910876543210">+91 91003 33463 </a>
            </p>
          </div>
        </div>
        <div className="site-links">
          <div className="col hide-mobile">
            <p>Hyderabad</p>
          </div>
          <div className="col">
            <ul>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/">E-monitoring</Link>
              </li>
              <li>
                <Link to="/">Team</Link>
              </li>
            </ul>
          </div>
          <div className="col hide-mobile">
            <ul>
              <li>
                <Link to="/">Interiors</Link>
              </li>
              <li>
                <Link to="/">Smart Home</Link>
              </li>
              <li>
                <Link to="/">Architecture</Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <ul>
              <li>
                <Link to="/terms">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms">Terms & Conditions</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="mini-footer">
          <div className="copyright">
            <p>Copyright 2023 @Sted_Studio. All rights reserved</p>
          </div>
          <div className="social-links">
            <div className="col">
              <a href="/">
                <img src={igIcon} alt="" />
              </a>
            </div>
            <div className="col">
              <a href="/">
                <img src={twIcon} alt="" />
              </a>
            </div>
            <div className="col">
              <a href="/">
                <img src={fbIcon} alt="" />
              </a>
            </div>
            <div className="col">
              <a href="/">
                <img src={inIcon} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
