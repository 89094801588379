import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./navbar.scss";
import linkUnderLine from "../../assets/icons/link-under-line.svg";
export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleHamMenu = () => {
    const hamMenuIcon = document.querySelector(".ham-menu");
    const navLinks = document.querySelector(".nav-links");
    hamMenuIcon.classList.toggle("active");
    navLinks.classList.toggle("active");
  };
  return (
    <div className="navbar">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="nav-links">
        <ul>
          <li
            className={`nav-link ${location.pathname === "/" ? "active" : ""}`}
          >
            <Link onClick={handleHamMenu} to="/">
              Home
            </Link>
            <img src={linkUnderLine} alt="" />
          </li>
          <li
            className={`nav-link ${
              location.pathname === "/about" ? "active" : ""
            }`}
          >
            <Link onClick={handleHamMenu} to="/about">
              About Us
            </Link>
            <img src={linkUnderLine} alt="" />
          </li>
          <li
            className={`nav-link ${
              location.pathname === "/blog" ? "active" : ""
            }`}
          >
            <Link onClick={handleHamMenu} to="/blog">
              Blog
            </Link>
            <img src={linkUnderLine} alt="" />
          </li>
          <li
            className={`nav-link ${
              location.pathname === "/contact" ? "active" : ""
            }`}
          >
            <Link onClick={handleHamMenu} to="/contact">
              Contact Us
            </Link>
            <img src={linkUnderLine} alt="" />
          </li>
          <li
            className={`nav-link ${
              location.pathname === "/faq" ? "active" : ""
            }`}
          >
            <Link onClick={handleHamMenu} to="/faq">
              FAQs
            </Link>
            <img src={linkUnderLine} alt="" />
          </li>
          <li
            className={`nav-link ${
              location.pathname === "/cost-estimator" ? "active" : ""
            }`}
          >
            <Link onClick={handleHamMenu} to="/cost-estimator">
              Cost Estimator
            </Link>
            <img src={linkUnderLine} alt="" />
          </li>
          <li className="nav-link">
            <button
              onClick={() => {
                navigate("/login");
                handleHamMenu();
              }}
            >
              Login
            </button>
          </li>
        </ul>
      </div>
      <div className="ham-menu" onClick={handleHamMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}
