import "./App.scss";
import Navbar from "./components/navbar/Navbar";
import { Routes, Route } from "react-router-dom";
import Home from "./components/pages/home/Home";
import Footer from "./components/footer/Footer";
import About from "./components/pages/about/About";
import Contact from "./components/pages/contact/Contact";
import Blog from "./components/pages/blog/Blog";
import Quote from "./components/pages/quote/Quote";
import BlogPage from "./components/pages/blog/BlogPage";
import Login from "./components/pages/login/Login";
import Services from "./components/pages/services/Services";
import Faq from "./components/pages/faq/Faq";
import Terms from "./components/pages/terms/Terms";
import QuoteGenerator from "./components/pages/quote-generator/QuoteGenerator";
import EditQuote from "./components/pages/quote-generator/edit/Edit";
import CostEstimator from "./components/pages/cost-estimator/Cost";
import { useEffect } from "react";
import goToTopIcon from "./assets/icons/go-to-top.svg";
function App() {
  const goToTop = ()=>{
    window.scrollTo(0,0);
  }
  useEffect(()=>{
    document.addEventListener('scroll',(e)=>{
      if(window.scrollY > document.body.scrollHeight * 0.1){
        document.querySelector('.go-top').classList.remove('hide');
      }else{
        document.querySelector('.go-top').classList.add('hide');
      }
    })
  },[])
  return (
    <>
      <div className="go-top hide" onClick={goToTop}>
        <img src={goToTopIcon} alt="go-top-icon" />
      </div>
      <Navbar />
      <Routes>
        <Route exact path="/" Component={Home} />
        <Route exact path="/about" Component={About} />
        <Route exact path="/contact" Component={Contact} />
        <Route exact path="/blog" Component={Blog} />
        <Route exact path="/quote" Component={Quote} />
        <Route exact path="/blog-post" Component={BlogPage}></Route>
        <Route exact path="/login" Component={Login} />
        <Route exact path="/services" Component={Services} />
        <Route exact path="/faq" Component={Faq} />
        <Route exact path="/cost-estimator" Component={CostEstimator} />
        <Route exact path="/terms" Component={Terms} />
        <Route exact path="/generate-quote" Component={QuoteGenerator} />
        <Route exact path="/generate-quote/edit/" Component={EditQuote} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
