import "./terms.scss";
import underLine from "../../../assets/icons/under-line-paint.svg";
export default function Terms() {
  return (
    <div className="terms">
      <div className="title">
        <h1>Terms and Conditions</h1>
        <img src={underLine} alt="" />
      </div>
      <div className="desc main">
        <p>
          By utilizing the Services, you acknowledge and agree to abide by these
          Terms, having read and comprehended them, and also commit to
          fulfilling the specified requirements. If you do not consent to all of
          these Terms or fail to comply with the mentioned requirements, please
          refrain from accessing the Platform or using the Services.
        </p>
      </div>
      <div className="body">
        <div className="section services">
          <div className="title">
            <h2>Services</h2>
          </div>
          <div className="desc">
            <ol>
              <li>
                Buildgenix serves as a platform connecting customers with
                various service providers (referred to as "SPs" individually).
                Our role is to assist customers in discovering and selecting an
                SP based on their specific preferences.
              </li>
              <li>
                Buildgenix acts as an intermediary, facilitating the completion
                of projects according to the agreement between the customer and
                an SP. Once the contract between the customer and the SP is
                executed and submitted to Buildgenix, and the project commences,
                we provide services to track the project's phases and
                development
              </li>
              <li>
                The supply of building materials is exclusively available to
                registered businesses (B2B).
              </li>
              <li>
                Individual customers (B2C) or end consumers are not eligible to
                purchase construction materials through Buildgenix's building
                material supply team.
              </li>
              <li>
                The Supply Chain Management (SCM) team operates within
                predetermined timelines for executing specific material
                categories.
              </li>
              <li>
                Buildgenix will issue the invoice directly to the business
                within 48 hours of delivery.
              </li>
              <li>
                Buildgenix will share leads of customers seeking assistance with
                plot-to-plot developers for a fixed commission fee. However,
                Buildgenix will not be involved in negotiating the price of the
                land being purchased or discussed by the customer.
              </li>
              <li>
                Buildgenix will provide digital promotion services to its client
                partners for a fixed fee. Buildgenix will not participate in
                negotiating the property's price in any way.
              </li>
              <li>
                Package rates are subject to the build-up area and the number of
                floors.
              </li>
            </ol>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Accessing and Using Our Site and Services</h2>
          </div>
          <div className="desc">
            <p>
              Initiating Service - You agree that the information provided when
              creating your account is accurate and complete, and you commit to
              keeping it up to date. For instance, certain notices may be sent
              to your account via email, and you agree to maintain a current and
              valid email address to receive such notices as per these Terms.
            </p>
            <p>
              Restrictions - You shall not, and you shall not permit any third
              party to: modify, copy, or reproduce the Services in whole or in
              part; reverse engineer, decompile, disassemble, or attempt to
              derive the source code or structure of the software used in the
              Services; provide, lease, or lend the Services to any third party;
              remove any proprietary notices or labels displayed on the
              Services; modify or create derivative works based on any part of
              the Services; or use the Services for any unlawful purpose or
              contrary to these Terms. You will (1) ensure that your users
              comply with these Terms, (2) make commercially reasonable efforts
              to prevent unauthorized access or use of the Services, and (3) use
              the Services only in accordance with the User Guide, applicable
              laws, and government regulations. (4) Regarding the supply of
              building materials, you confirm that you are a compliant business
              under applicable law and not an individual.
            </p>
            <p>
              Charges - You acknowledge that utilizing any SP's Services will
              incur charges for the products or services provided by said SP
              ("SP Charges"). Additionally, using Buildgenix's Services will
              result in charges for the products or services provided by
              Buildgenix ("Buildgenix Charges"). Collectively, these charges are
              referred to as "Charges." You will be notified of the Charges
              before the provision of products or services by Buildgenix or the
              SPs, including any applicable cancellation fees and terms. It is
              advised to confirm the Charges each time you request products or
              services. Applicable taxes will be added to all Charges as
              required by law.
            </p>
            <p>
              Payment - Payments for Charges are due immediately upon the
              provision or initiation of applicable products and services.
              Payments are considered final and non-refundable, subject to
              Buildgenix's discretion. Buildgenix merely facilitates your
              payment to the SP. You acknowledge and agree that your purchases
              of services and products from any SP constitute transactions
              between you and that specific SP, not involving Buildgenix.
            </p>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Provision of Services</h2>
          </div>
          <div className="desc">
            <p>
              Technical Support - As part of our Services, we offer standard
              technical support to assist you. Buildgenix provides access to its
              business Platform for registered users, including SPs and End
              Customers, facilitating the provision of services between SPs and
              End Customers, as well as payment transactions. Buildgenix acts
              neither as a principal nor an agent for any party involved.
              Therefore, Buildgenix cannot be held liable for any transactions
              conducted through its platform.
            </p>
            <p>
              Buildgenix strictly prohibits users from uploading any illegal,
              defamatory, discriminatory, improper, indecent, or objectionable
              content or materials on the platform. In such cases, Buildgenix
              reserves the right to immediately delete the user's account
              without prior notice.
            </p>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Service Cancellation Policy</h2>
          </div>
          <div className="desc">
            <p>
              Buildgenix facilitates connections between customers and service
              providers in the construction industry (referred to as "SP"). Our
              aim is to assist customers in finding and selecting an SP based on
              their specific preferences and requirements.
            </p>
            <p>
              At Buildgenix, we take pride in delivering transparent and
              reliable construction services. We invest significant effort in
              understanding our customers' needs, providing designs, and
              furnishing other relevant documents and details.
            </p>
            <p>
              In the unlikely event that a customer decides to cancel a project
              after making a payment towards the booking amount, they must
              inform Buildgenix in writing via email to info@buildgenix.in (or
              any other provided email address) or by sending a registered post
              with an acknowledgment receipt. The cancellation communication
              should include the reasons for the cancellation. Upon receiving
              such communication, Buildgenix will review
            </p>
            <p>
              For cancellations made more than 48 hours after the payment of the
              booking amount, a non-refundable cancellation fee equivalent to 2%
              of the project value will be applicable. Additionally, for
              cancellations beyond the 48-hour period, Buildgenix reserves the
              right to collect amounts based on the project stage and work
              completed. The specific cancellation amounts will be deducted
              according to the terms stated in the contract between Buildgenix
              and the customer. It's important to note that these deductions
              include applicable taxes and are calculated based on the total
              non-discounted value of the project as per the corresponding
              documents mentioned above. The cancellation fee(s) will be
              adjusted against the amount(s) previously paid by the customer.
            </p>
            <p>
              In cases where architectural/structural designs have been
              completed, digital surveys have been conducted, or soil tests have
              been performed, relevant information and final documentation may
              be shared with the customer, subject to the applicable charges
              communicated by Buildgenix.
            </p>
            <p>
              If the costs incurred by Buildgenix for the aforementioned
              services exceed the amounts already paid by the customer, the
              customer will be responsible for paying the outstanding balance
              within 15 working days of receiving a written notice from
              Buildgenix.
            </p>
            <p>
              Once the final value of the refund amount is mutually agreed upon
              by Buildgenix and the customer, any refund due to the customer,
              after deducting appropriate fees and adjustments, will be
              transferred to the customer's provided bank account within 30
              business days.
            </p>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Account Information</h2>
          </div>
          <div className="desc">
            <ol>
              <li>
                Buildgenix may upload Transaction Information on the Platform,
                which includes details of customer transactions, such as goods
                or services sold, costs, amounts paid and payable, and specific
                product/service information. Transaction Information may be
                communicated between SPs and customers through various
                electronic mediums like phone calls, text messages, WhatsApp,
                email, etc., based on the contact details provided on the
                Platform.
              </li>
              <li>
                When creating or uploading the first Transaction Information
                related to a customer, Buildgenix will inform the customer about
                the use of the Platform to record such information. Buildgenix
                will seek the customer's explicit consent for:
                <ul>
                  <li>
                    creating a customer profile on the Platform, which requires
                    sharing the customer's phone number and contact details;
                  </li>
                  <li>
                    sharing Transaction Information with Buildgenix and third
                    parties to enhance the Services;
                  </li>
                  <li>
                    receiving communications from Buildgenix regarding
                    transaction information, payment requests, information about
                    the Services, promotional offers, and services from
                    Buildgenix and its third-party partners, and other related
                    matters.
                  </li>
                </ul>
              </li>
              <li>
                If a customer declines to provide consent or withdraws consent,
                the SP must immediately cease using the Services for that
                customer.
              </li>
            </ol>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Your Responsibilities</h2>
          </div>
          <div className="desc">
            <ol>
              <li>
                The User represents and warrants that all information provided
                through or in relation to the Services is accurate and complete
                at the time of agreeing to these Terms. The User agrees to
                promptly notify Buildgenix of any changes to the provided
                information during the term of these Terms. Buildgenix disclaims
                any responsibility or liability for losses or damages incurred
                due to incorrect, incomplete, inaccurate, or misleading
                information or the failure to disclose any material facts.
              </li>
              <li>
                The User is solely responsible for complying with applicable
                laws and will be held liable for any breaches of obligations in
                this regard.
              </li>
              <li>
                The User is required to cooperate with Buildgenix in its defence
                against any legal proceedings arising from the User's breach of
                obligations under these Terms.
              </li>
              <li>
                While Buildgenix makes reasonable efforts to provide daily
                backups of Transaction Information, Users are advised to
                independently save, backup, and archive their own Transaction
                Information regularly.
              </li>
              <li>
                The User must only use the Services as expressly permitted in
                these Terms. Prohibited actions include, but are not limited to:
                <ul>
                  <li>
                    infringing upon any proprietary rights of third parties,
                    such as copyrights, patents, trademarks, or trade secrets;
                  </li>
                  <li>
                    copying, displaying, distributing, modifying, publishing,
                    reproducing, storing, transmitting, posting, translating,
                    creating derivative works, or licensing the Services, except
                    as permitted under these Terms;
                  </li>
                  <li>
                    transmitting data or uploading materials containing harmful
                    computer code, such as viruses, worms, spyware, or adware;
                  </li>
                  <li>
                    using any automated device or manual process to monitor or
                    copy the Platform or Services
                  </li>
                  <li>
                    engaging in systematic retrieval of content from the
                    Platform or Services to create collections, compilations,
                    databases, or directories;
                  </li>
                  <li>
                    using the Services unlawfully, for fraudulent or malicious
                    activities, or in a manner inconsistent with these Terms
                  </li>
                  <li>violating applicable laws in any way.</li>
                </ul>
              </li>
            </ol>
            <p>Ownership of Intellectual Property</p>
            <ol>
              <li>
                Buildgenix is the owner or licensee of all intellectual property
                rights related to the Platform and Services. Users are granted a
                limited, non-transferable, non-sub licensable, and revocable
                license to use the Platform and Services in accordance with
                these Terms and any provided instructions.
              </li>
              <li>
                Users may provide suggestions and feedback ("Feedback")
                regarding the Platform or Services. Buildgenix reserves the
                right to use, disclose, and exploit the Feedback without any
                restrictions or obligations to the User.
              </li>
              <li>
                These Terms do not grant Users any rights or licenses to
                Buildgenix's or any third party's intellectual property rights,
                except as expressly stated.
              </li>
            </ol>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Duration and Termination</h2>
          </div>
          <div className="desc">
            <ol>
              <li>
                These Terms will remain in effect until terminated as described
                herein.
              </li>
              <li>
                Buildgenix may terminate a User's access to the Services, or any
                part thereof, at its sole discretion if the User violates these
                Terms.
              </li>
              <li>
                Upon termination:
                <ul>
                  <li>Services will no longer be accessible;</li>
                  <li>
                    Users will not be able to avail any features of the
                    Services; and
                  </li>
                  <li>
                    These Terms will terminate, except for provisions intended
                    to survive termination.
                  </li>
                </ul>
              </li>
              <li>
                These Terms will terminate, except for provisions intended to
                survive termination.
              </li>
            </ol>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Disclaimers and Warranties</h2>
          </div>
          <div className="desc">
            <ol>
              <li>Use of the Services is at the User's own risk.</li>
              <li>
                Buildgenix does not endorse or guarantee the qualifications or
                capabilities of the service providers available through the
                Services.
              </li>
              <li>
                Trademarks and logos displayed on the Services belong to their
                respective owners. Estimates provided are for guidance only and
                not a guarantee of project costs.
              </li>
              <li>
                Users are responsible for their interactions with third parties
                and should exercise caution. Buildgenix is not liable for any
                losses or damages resulting from such interactions.
              </li>
              <li>
                Buildgenix is a technology platform provider and not responsible
                for the services provided by third-party service providers
              </li>
              <li>
                The Services are provided on an "as is" and "as available"
                basis, and Buildgenix does not guarantee uninterrupted or
                error-free operation or that the Services will meet all
                requirements
              </li>
              <li>
                Buildgenix disclaims all warranties to the fullest extent
                permitted by law, including but not limited to merchantability,
                fitness for a particular purpose, accuracy, and
                non-infringement.
              </li>
              <li>
                Users assume full responsibility for their use of the Services,
                and Buildgenix disclaims any liability arising from such use.
              </li>
            </ol>
          </div>
          <div className="desc">
            <p>
              Buildgenix, its affiliates, and related parties shall not be
              liable for any loss or damage resulting from:
            </p>
            <ul>
              <li>
                Use or unavailability of the Services, including third-party
                services;
              </li>
              <li>
                Defects, interruptions, or delays in the Services, communication
                failures, unauthorized access, or security breaches; or
              </li>
              <li>
                Services being non-operational for any period of time, to the
                fullest extent permitted by law.
              </li>
            </ul>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Indemnification</h2>
          </div>
          <div className="desc">
            <p>
              You are obligated to indemnify, at our discretion, defend, and
              hold us, our parent companies, subsidiaries, affiliates, and their
              officers, associates, successors, assigns, licensors, employees,
              directors, agents, and representatives harmless from any claim,
              demand, lawsuits, legal proceedings, losses, liabilities, damages,
              and costs (including, but not limited to, damages, liabilities,
              settlements, costs, and attorneys' fees) arising from or related
              to your access to the Services, use of the Services, violation of
              these Terms, or any infringement by any third party who may use
              your account with us.
            </p>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Limitation of Liability</h2>
          </div>
          <div className="desc">
            <p>
              Regardless of anything stated to the contrary in these Terms,
              neither Buildgenix nor any of its affiliates or related parties
              shall be liable to you or any third party for any indirect,
              incidental, special, or consequential damages, or any loss of
              revenue or profits arising directly or indirectly from these Terms
              or the Services. To the maximum extent permitted by law, you agree
              to waive, release, discharge, and hold Buildgenix, its affiliates,
              subsidiary companies, parent companies, and each of their
              directors, officers, employees, and agents harmless from any and
              all claims, losses, damages, liabilities, expenses, and causes of
              action arising from the Services.
            </p>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Consent to Data Usage</h2>
          </div>
          <div className="desc">
            <ol>
              <li>
                By agreeing to these Terms, you acknowledge and consent that we
                may collect and use your information, technical data, and
                related information in accordance with our Privacy Policy.
              </li>
              <li>
                We may utilize information and data concerning your use of the
                Services for analytics, identification of trends, and
                statistical purposes to improve the effectiveness and efficiency
                of the Platform.
              </li>
              <li>
                Subject to applicable laws, we may be required by law
                enforcement agencies or the government and related bodies to
                disclose user data in connection with criminal proceedings. In
                such instances, you understand and agree that we reserve the
                right to share such data with relevant agencies or bodies.
              </li>
            </ol>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Modification</h2>
          </div>
          <div className="desc">
            <p>
              Buildgenix reserves the right to add, modify, or discontinue the
              Services (or any part thereof) at any time, with or without cause.
              We shall not be held liable for any such addition, modification,
              suspension, or discontinuation of the Services.
            </p>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Compliance with Applicable Laws and Regulations</h2>
          </div>
          <div className="desc">
            <p>
              You agree to comply with all central, state, and local laws,
              rules, and regulations relating to the use of the Services, and
              you assume all liability and responsibility for your use of the
              Services. If Buildgenix reasonably determines that your use of the
              Services violates applicable laws, we reserve the right to suspend
              or terminate your use of the Services immediately. You will be
              solely responsible for any penalties or sanctions resulting from
              legal noncompliance.
            </p>
            <p>
              Possible Suspension or Termination of Service for Non-Compliance -
              If Buildgenix reasonably believes that your use of the Services
              violates central, state, and/or local rules or regulations, or
              harasses consumers or businesses, Buildgenix may suspend its
              Service to prevent further use. Additionally, Buildgenix may
              review your Service settings.
            </p>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Jurisdiction, Governing Laws, and Dispute Resolution</h2>
          </div>
          <div className="desc">
            <ol>
              <li>
                These Terms shall be governed by and interpreted in accordance
                with the laws of India. Any issues arising from these Terms or
                the use of the Services shall have the exclusive jurisdiction of
                the courts in Bangalore.
              </li>
              <li>
                Any controversies, conflicts, disputes, or differences arising
                from these Terms shall be resolved through arbitration in
                Bangalore, in accordance with the Arbitration and Conciliation
                Act, 1996. The arbitration tribunal shall consist of 1 (one)
                arbitrator appointed by Buildgenix. The language of the
                arbitration proceedings shall be English.
              </li>
              <li>
                The arbitration proceedings shall remain confidential, except
                when disclosure is required by law or to legal advisors. The
                arbitrator's decision shall be final and binding on all parties
                involved.
              </li>
              <li>
                Each party shall bear its own costs with respect to any dispute.
              </li>
            </ol>
          </div>
        </div>
        <div className="section">
          <div className="title">
            <h2>Miscellaneous Provisions</h2>
          </div>
          <div className="desc">
            <ol>
              <li>
                Modification - Buildgenix reserves the right to modify these
                Terms and introduce new or additional terms or conditions for
                the use of the Services. Such changes will be communicated to
                you and, unless expressly rejected (resulting in termination of
                these Terms), will take effect immediately and become part of
                these Terms. If you refuse to accept these changes, these Terms
                will be terminated.
              </li>
              <li>
                Severability - If any provision of these Terms is deemed
                unlawful or unenforceable by a court or competent authority, the
                other provisions of these Terms will remain in effect. If any
                unlawful or unenforceable provision could be valid or
                enforceable if part of it were deleted, that part will be deemed
                deleted, and the remaining provision will continue to be
                effective (unless deleting that part contradicts the clear
                intention of the clause, in which case the entire relevant
                provision will be deemed deleted).
              </li>
              <li>
                Assignment - You may not license, sell, transfer, or assign your
                rights, obligations, or covenants under these Terms without our
                prior written consent. We may grant or withhold this consent at
                our sole discretion, subject to any conditions we deem
                appropriate. We reserve the right to assign our rights to any
                affiliates, subsidiaries, parent companies, or any successor in
                interest of any business associated with the Services, without
                prior notice to you.
              </li>
              <li>
                Notices - All notices, requests, demands, and determinations to
                Buildgenix under these Terms (except routine operational
                communications) should be sent to info@buildgenix.in. For any
                questions regarding these Terms, please contact us via email at
                info@buildgenix.in.
              </li>
              <li>
                Third-Party Rights - No third party shall have the right to
                enforce any terms contained herein
              </li>
              <li>
                Translations - Buildgenix shall not be liable for inadequate
                performance due to conditions beyond its reasonable control,
                such as natural disasters, acts of war or terrorism, riots,
                labor conditions, governmental actions, or internet
                disturbances.
              </li>
              <li>
                Force Majeure - No third party shall have the right to enforce
                any terms contained herein.
              </li>
              <li>
                Entire Agreement - These Terms constitute the entire agreement
                between you and Buildgenix regarding the Services, superseding
                all prior or contemporaneous communications of any kind between
                you and Buildgenix concerning the Services.
              </li>
              <li>
                Waiver - No waiver of any provision of these Terms shall be
                deemed a further or continuing waiver of such provision or any
                other provision, and Buildgenix's failure to assert any right or
                provision under these Terms shall not constitute a waiver of
                such right or provision.
              </li>
              <li>
                Attorney Fees - If the Buildgenix Parties take legal action
                against you due to your violation of these Terms, the Buildgenix
                Parties shall be entitled to recover from you, and you agree to
                pay, all reasonable attorneys' fees and costs incurred, in
                addition to any other relief granted to the Buildgenix Parties.
              </li>
              <li>
                Your use of the website and app is governed by these Terms of
                Use, in addition to the Privacy Policy.
              </li>
              <li>
                We may require you to create an account on our website and app,
                which may involve specifying a password and necessary login
                details. As a registered user with an account and password, you
                are responsible for maintaining the confidentiality of your
                password.
              </li>
              <li>
                Confidentiality: You must maintain confidentiality regarding all
                information and agree not to disclose it unless required by law.
                You also authorize us to contact you with offers through emails,
                calls, SMS, or any other mediums for campaigns, promotions, or
                similar activities. You agree to indemnify us, along with our
                directors and employees, against any losses and damages incurred
                due to any action taken by TRAI or any other authority resulting
                from any erroneous complaint raised by you in this regard.
              </li>
              <li>
                Warranties: The 5-year warranty mentioned refers to the warranty
                provided by the contractor against structural defects and is
                governed by the terms and conditions specified in the separate
                Master Agreements entered into by the Contractors for
                construction purposes. Buildgenix does not provide any
                warranties for structural defects arising from construction or
                any other reasons.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
