import { useState } from "react";
import underLine from "../../../assets/icons/under-line-paint.svg";
import "./quote.scss";
import emailjs from "@emailjs/browser";
export default function Quote() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    projectType: "residential",
    projectDetails: "",
    budgetRange: "",
    contactMethod: "",
    pointOfContact: "referral",
    others: "",
    terms: false,
  });
  const setSelection = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [formError, setFormError] = useState(null);
  const submitQuote = (e) => {
    if (formData.name === "") {
      setFormError("name cannot be empty");
      return;
    }
    if (formData.email === "") {
      setFormError("email cannot be empty");
      return;
    } else {
      if (!validateEmail(formData.email)) {
        setFormError("invalid email");
        return;
      }
    }
    if (formData.phone === "") {
      setFormError("phone cannot be empty");
      return;
    } else {
      if (
        !/^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
          formData.phone
        )
      ) {
        setFormError("invalid phone");
        return;
      }
    }

    if (!formData.terms) {
      setFormError("Please accept terms and conditions");
      return;
    }

    setFormError(null);

    emailjs
      .send(
        "service_sujs3mc",
        "template_hachxqf",
        formData,
        "3UsAgltKFnnlqaGve"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          e.target.textContent = "sent successfully";
          setTimeout(() => {
            setFormData({
              name: "",
              phone: "",
              email: "",
              projectType: "residential",
              projectDetails: "",
              budgetRange: "",
              contactMethod: "",
              pointOfContact: "referral",
              others: "",
            });
            e.target.textContent = "send";
          }, 2000);
        },
        function (error) {
          console.log("FAILED...", error);
          setFormError("error sending query. Try again");
          e.target.textContent = "send";
        }
      );

    console.log(formData);
  };
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  return (
    <div className="quote">
      <div className="container">
        <div className="title">
          <h1>Get a quote</h1>
          <img src={underLine} alt="" />
        </div>
        <div className="desc">
          <p className="tagline">Ready to start your construction project?</p>
          <p className="instruction">
            Fill out the form below to get a personalized quote from our
            experienced team at BuildGenix. We'll get back to you with all the
            information you need to move forward with confidence.
          </p>
        </div>
        <div className="body">
          <div className="field-container">
            <label htmlFor="name">Name*</label>
            <input
              type="text"
              name=""
              id="name"
              placeholder="enter your name here"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  name: e.target.value,
                });
              }}
              value={formData.name}
            />
          </div>
          <div className="field-container">
            <label htmlFor="email">Email*</label>
            <input
              type="text"
              name=""
              id="email"
              placeholder="enter your email ID"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  email: e.target.value,
                });
              }}
              value={formData.email}
            />
          </div>
          <div className="field-container">
            <label htmlFor="phone">Phone No. *</label>
            <input
              type="text"
              name=""
              id="phone"
              placeholder="eg. 9876543210"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  phone: e.target.value,
                });
              }}
              value={formData.phone}
            />
          </div>
          <div className="field-container">
            <label htmlFor="project-type">Project Type *</label>
            <ul className="options">
              <li
                onClick={() => {
                  setSelection("residential", "projectType");
                }}
                className={
                  formData.projectType === "residential" ? "active" : ""
                }
              >
                Residential
              </li>
              <li
                onClick={() => {
                  setSelection("commercial", "projectType");
                }}
                className={
                  formData.projectType === "commercial" ? "active" : ""
                }
              >
                Commercial
              </li>
              <li
                onClick={() => {
                  setSelection("rennovation", "projectType");
                }}
                className={
                  formData.projectType === "rennovation" ? "active" : ""
                }
              >
                Rennovation
              </li>
              <li
                onClick={() => {
                  setSelection("others", "projectType");
                }}
                className={formData.projectType === "others" ? "active" : ""}
              >
                Others
              </li>
            </ul>
          </div>
          <div className="field-container">
            <label htmlFor="details">Project Details</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="Provide a brief description of your project, including the scope of work, timelines, and any specific requirements you may have."
              onChange={(e) => {
                setFormData({
                  ...formData,
                  projectDetails: e.target.value,
                });
              }}
              value={formData.projectDetails}
            ></textarea>
          </div>
          <div className="field-container">
            <label htmlFor="budget">Budget Range </label>
            <input
              type="text"
              name=""
              id="budget"
              placeholder="in Rupees"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  budgetRange: e.target.value,
                });
              }}
              value={formData.budgetRange}
            />
          </div>
          <div className="field-container">
            <label htmlFor="contact-method">Preferred contact method</label>
            <input
              type="text"
              name=""
              id="contact-method"
              placeholder="e-mail or phone"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  contactMethod: e.target.value,
                });
              }}
              value={formData.contactMethod}
            />
          </div>
          <div className="field-container">
            <label htmlFor="project-type">How did you hear about us?</label>
            <ul className="options">
              <li
                onClick={() => {
                  setSelection("refferal", "pointOfContact");
                }}
                className={
                  formData.pointOfContact === "referral" ? "active" : ""
                }
              >
                Referral
              </li>
              <li
                onClick={() => {
                  setSelection("online", "pointOfContact");
                }}
                className={formData.pointOfContact === "online" ? "active" : ""}
              >
                Online Search
              </li>
              <li
                onClick={() => {
                  setSelection("social_media", "pointOfContact");
                }}
                className={
                  formData.pointOfContact === "social_media" ? "active" : ""
                }
              >
                Social Media
              </li>
              <li
                onClick={() => {
                  setSelection("advertisement", "pointOfContact");
                }}
                className={
                  formData.pointOfContact === "advertisement" ? "active" : ""
                }
              >
                Advertisement
              </li>
              <li
                onClick={() => {
                  setSelection("others", "pointOfContact");
                }}
                className={formData.pointOfContact === "others" ? "active" : ""}
              >
                Others
              </li>
            </ul>
          </div>
          <div className="field-container">
            <label htmlFor="details">Additional Comments</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="Add any additional comments or questions you may have."
              onChange={(e) => {
                setFormData({
                  ...formData,
                  others: e.target.value,
                });
              }}
              value={formData.others}
            ></textarea>
          </div>
          <div className="field-container terms">
            <input
              type="checkbox"
              name="terms"
              id="terms"
              checked={formData.terms}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  terms: e.target.checked,
                });
              }}
            />
            <label htmlFor="terms">
              By submitting the form you agree to our privacy policy and terms
              of service *
            </label>
          </div>
          <div className="error">
            <p>{formError ? `*${formError}` : ""}</p>
          </div>
          <div className="field-container submit" onClick={submitQuote}>
            <button>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
}
