import { useState } from "react";
import "./contact.scss";
import underLine from "../../../assets/icons/under-line-paint.svg";
import mailIcon from "../../../assets/icons/mail-icon.svg";
import mapIcon from "../../../assets/icons/map-icon.svg";
import callIcon from "../../../assets/icons/call-icon.svg";
import twIcon from "../../../assets/icons/tw.svg";
import inIcon from "../../../assets/icons/in.svg";
import fbIcon from "../../../assets/icons/fb.svg";
import igIcon from "../../../assets/icons/ig.svg";
import titleBg from "../../../assets/icons/title-bg-rect.svg";
import emailjs from "@emailjs/browser";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    purpose: "",
    message: "",
    phone: "",
  });
  const [formError, setFormError] = useState(null);
  const submitForm = (e) => {
    var phoneno = /^\d{10}$/;
    e.target.textContent = "...";
    if (formData.name.length === 0) {
      setFormError("name cannot be empty");
      return;
    }
    if (formData.phone.length === 0) {
      setFormError("phone number cannot be empty");
      if (!formData.phone.match(phoneno)) {
        setFormError("invalid phone number");
        return;
      }
      return;
    }
    setFormError(null);
    emailjs
      .send(
        "service_sujs3mc",
        "template_4y95puq",
        formData,
        "3UsAgltKFnnlqaGve"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          e.target.textContent = "sent successfully";
          setTimeout(() => {
            setFormData({
              name: "",
              purpose: "",
              message: "",
              phone: "",
            });
            e.target.textContent = "send";
          }, 2000);
        },
        function (error) {
          console.log("FAILED...", error);
          setFormError("error sending query. Try again");
        }
      );
  };
  const socialIcons = [
    {
      name: "facebook",
      img: fbIcon,
      link: "",
    },
    {
      name: "instagram",
      img: igIcon,
      link: "",
    },
    {
      name: "twitter",
      img: twIcon,
      link: "",
    },
    {
      name: "linkedin",
      img: inIcon,
      link: "",
    },
  ];
  return (
    <div className="contact">
      <div className="title">
        <h1>We would love to hear from you!</h1>
        <img src={underLine} alt="" />
      </div>
      <div className="body">
        <div className="col">
          <div className="form">
            <div className="form-title">
              <h2>Say Hi!</h2>
              <p>We’d like to talk with you.</p>
            </div>
            <div className="form-body">
              <div className="field-container">
                <label htmlFor="name">My name is</label>
                <input
                  type="text"
                  name=""
                  id="name"
                  value={formData.name}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      name: e.target.value,
                    });
                  }}
                  placeholder="Full Name"
                />
              </div>
              <div className="field-container">
                <label htmlFor="phone">Reach me at</label>
                <input
                  type="tel"
                  name=""
                  id="phone"
                  value={formData.phone}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      phone: e.target.value,
                    });
                  }}
                  placeholder="Phone Number"
                />
              </div>
              <div className="field-container">
                <label htmlFor="purpose">Im looking for</label>
                <input
                  type="text"
                  name=""
                  id="purpose"
                  value={formData.purpose}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      purpose: e.target.value,
                    });
                  }}
                  placeholder="What you love"
                />
              </div>
              <div className="field-container">
                <label htmlFor="message">Your message</label>
                <textarea
                  name=""
                  id="message"
                  value={formData.message}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      message: e.target.message,
                    });
                  }}
                  placeholder="I want to say that..."
                  rows={5}
                />
              </div>

              {formError ? (
                <div className="error">
                  <p>{`*${formError}`}</p>
                </div>
              ) : (
                ""
              )}
              <div className="submit">
                <button onClick={submitForm}>Submit</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="content">
            <div className="title">
              <h2>Contact Information</h2>
              <p>
                Fill up the form and our Team will get back to you within 24
                hours.
              </p>
            </div>
            <div className="info">
              <div className="details">
                <div className="detail">
                  <div className="icon">
                    <img src={callIcon} alt="" />
                  </div>
                  <div className="value">
                    <p>
                      <a href="tel:+918801036359">88010 36359</a>
                    </p>
                  </div>
                </div>
                <div className="detail">
                  <div className="icon">
                    <img src={mailIcon} alt="" />
                  </div>
                  <div className="value">
                    <p>
                      <a href="mailto:info@buildgenix.com">
                        info@buildgneix.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="detail">
                  <div className="icon">
                    <img src={mapIcon} alt="" />
                  </div>
                  <div className="value">
                    <p>
                      <a href="/">Hyderabad</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="social-media">
              {socialIcons.map((icon, i) => {
                return (
                  <div className="icon" key={`soci-${i}`}>
                    <a href={icon.link}>
                      <img src={icon.img} alt={icon.name} />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
