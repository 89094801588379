import React, { useEffect, useState } from "react";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "./edit.scss";
import TextField from "@mui/material/TextField";
import underLine from "../../../../assets/icons/under-line-paint.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import pdfLogo from "../../../../assets/images/pdf-logo.png";
import waterMark from "../../../../assets/images/water-mark.png";
import { getPackageData } from "../../packages";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
} from "@react-pdf/renderer";
// Create styles
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});
var modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
  ],
};
var formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];
const styles = StyleSheet.create({
  image: {
    width: "200px",
    marginBottom: 5,
  },
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 30,
    backgroundColor: "#FAFFEB",
    paddingTop: 25,
    paddingBottom: 20,
  },
  text: {
    fontSize: 12,
    marginTop: 2,
    paddingHorizontal: 0,
  },
  titleDesc: {
    fontFamily: "Oswald",
    fontSize: 12,
  },
  title: {
    fontSize: 20,
    marginBottom: 5,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  footer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    marginHorizontal: 50,
    borderTop: "1px solid #000",
    paddingTop: 5,
  },
  waterMark: {
    opacity: 0.3,
    width: "50%",
    position: "absolute",
    top: "50%",
    left: "30%",
  },
});

export default function QuoteEditor() {
  const location = useLocation();
  const navigate = useNavigate();
  const [pdfPreviewModal, setPdfPreviewModal] = useState(false);
  const [packageSections, setPackageSections] = useState({});
  const [newCostModel, setNewCostModel] = useState(false);
  const [newCostData, setNewCostData] = useState({
    item: "Ground Floor 1 units of carParking",
    rate: 0,
    quantity: 0,
    cost: 0,
    isFreebie: false,
    unitType: "sqft",
  });
  const [editQuoteData, setEditQuoteData] = useState({
    name: "",
    packageName: "",
    packageSections: {},
    detailsDesc: "",
    totalPackageCost: 0,
    totalSpecificationCost: 0,
    builtUpArea: 0,
    packageRate: 2260,
    floorsTitle: "",
    itemWiseSpecs: [
      {
        item: "Ground Floor 1 units of carParking",
        rate: 0,
        quantity: 0,
        cost: 0,
        isFreebie: false,
        unitType: "sqft",
      },
    ],
    totalDiscountPercentage: 0,
    nonDiscountValue: 0,
    totalCost: 0,
    itemwiseCost: 0,
    totalFreebieCost: 0,
    finalCost: 0,
  });
  const normalAreas = ["1BHK", "2BHK", "3BHK"];
  const getFloorSuffix = (i) => {
    const suffixes = ["st", "nd", "rd"];
    if (i <= 3) {
      return suffixes[i - 1];
    }
    return "th";
  };
  const generateDesc = (quoteData) => {
    const desc = `
\u2022 Plot Area = ${quoteData.length * quoteData.breadth} sq.ft <br />
${quoteData.floors
  .map((rec, i) => {
    var floor = `${i}${getFloorSuffix(i)} Floor`;
    if (i === 0) floor = "Ground Floor";
    return `${
      quoteData.duplex.required ? "\u2022 Duplex -" : "\u2022"
    } ${floor} => ${rec.units
      .map(
        (unit, j) =>
          `${unit.floorArea} sq.ft (${unit.numberOfUnits} units of ${
            unit.type
          }) ${j < rec.units.length - 1 ? "+" : ""} `
      )
      .join("")}<br />`;
  })
  .join(" ")}
<br />\u2022 Package Built Up Area => ${getItemArea(quoteData)} sq.ft.
<br />\u2022 Total Built Up Area = ${quoteData.builtupArea} sq.ft.
${
  quoteData.duplex.required
    ? `* As there is a duplex in the plan, a headroom of ${quoteData.duplex.unitsData.reduce(
        (a, b) => {
          return a + b.area;
        },
        0
      )} sq.ft. included in the package built up area.`
    : ""
}
<br />\u2022 Height of the building = ${quoteData.height}
<br />\u2022 Number of roads Surrounding the plot = ${
      quoteData.roads.count
    }<br />
${quoteData.roads.units.map((rec, i) => {
  return `\u2022 Road no.${i + 1} width = ${rec.value} ft<br />\u2022 Road no.${
    i + 1
  } side = ${rec.side}`;
})}
<br />\u2022 Total Setback = ${quoteData.minimumSetBack} sq.ft.
<br />\u2022 Changes in the builtup area will change the project cost.
`;
    return desc;
  };

  const handleProcedureContentChange = (content) => {
    // console.log("content---->", content);
  };

  const getItemWiseSpecifications = (quoteData) => {
    const area70 = ["Utility", "Open Area", "Parking", "Balcony", "Kitchen"];
    var specifications = [];
    var obj = {
      item: "",
      rate: 0,
      quantity: 0,
      cost: 0,
      isFreebie: false,
      unitType: "sqft",
    };
    var str = ``;
    var floorName = ``;
    var quantity = 0;
    var cost = 0;
    var otherItems = [];
    //calculating floor cost -  cost as original
    quoteData.floors.forEach((rec, i) => {
      str += `${
        i === 0 ? "Ground Floor + " : `${i}${getFloorSuffix(i)} Floor +`
      } `;
      floorName = `${
        i === 0 ? "Ground Floor " : `${i}${getFloorSuffix(i)} Floor`
      } `;
      rec.units.forEach((unit) => {
        if (normalAreas.includes(unit.type)) {
          cost +=
            parseFloat(unit.floorArea) *
            parseInt(quoteData.packages.packageRate);
          quantity += unit.floorArea;
        } else {
          // calculating other items - cost as 70% of package cost
          otherItems.push({
            ...obj,
            item: `${floorName} ${unit.type}`,
            cost: parseFloat(
              parseFloat(unit.floorArea) *
                0.7 *
                parseFloat(quoteData.packages.packageRate)
            ).toFixed(2),
            unit: "sqft",
            quantity: parseFloat(unit.floorArea).toFixed(2),
            rate: (parseFloat(quoteData.packages.packageRate) * 0.7).toFixed(2),
          });
        }
      });
    });

    str = str.slice(0, str.length - 2);
    specifications.push({
      ...obj,
      item: str,
      rate: quoteData.packages.packageRate,
      quantity: quantity,
      cost: cost,
    });
    specifications = [...specifications, ...otherItems];
    // other units
    cost = 0;
    quantity = 0;
    str = ``;

    if (quoteData.compoundWall.required) {
      cost = 0;
      var area;
      str = "";
      if (quoteData.compoundWall.type === "default") {
        quantity =
          2 * (parseFloat(quoteData.length) + parseFloat(quoteData.breadth));
        str = `Compound Wall Construction ${quantity}rft`;
        specifications.push({
          ...obj,
          item: str,
          unitType: "rft",
          cost: (quantity * 1060).toFixed(2),
          quantity: quantity,
          rate: 1060,
        });
      } else {
        str = `Compound Wall Construction ${quoteData.compoundWall.runningFeet}rft`;
        specifications.push({
          ...obj,
          item: str,
          unitType: "rft",
          cost: (parseFloat(quoteData.compoundWall.runningFeet) * 1060).toFixed(
            2
          ),
          quantity: parseFloat(quoteData.compoundWall.runningFeet),
          rate: 1060,
        });
        if (
          quoteData.compoundWall.mainGateDimensions.length &&
          quoteData.compoundWall.mainGateDimensions.height
        ) {
          area =
            parseFloat(quoteData.compoundWall.mainGateDimensions.length) *
            parseFloat(quoteData.compoundWall.mainGateDimensions.height);
          cost = parseFloat(area * 250).toFixed(2);
          str = `Main Gate ${area.toFixed(2)} sqft`;
          specifications.push({
            ...obj,
            item: str,
            unitType: "sqft",
            cost: cost,
            quantity: area,
            rate: 250,
          });
        }
        if (
          quoteData.compoundWall.extraGateDimensions.length &&
          quoteData.compoundWall.extraGateDimensions.height
        ) {
          area =
            parseFloat(quoteData.compoundWall.extraGateDimensions.length) *
            parseFloat(quoteData.compoundWall.extraGateDimensions.height);
          cost = parseFloat(area * 250).toFixed(2);
          str = `Extra Gate ${area.toFixed(2)}sqft`;
          specifications.push({
            ...obj,
            item: str,
            unitType: "sqft",
            cost: cost,
            quantity: area,
            rate: 250,
          });
        }
      }
    }
    // console.log(specifications);
    return specifications;
  };
  const deleteCost = (index) => {
    var costs = editQuoteData.itemWiseSpecs;
    var diffCost = parseFloat(costs[index].cost);
    var totalDiscountPercentage =
      parseFloat(editQuoteData.totalDiscountPercentage) || 1;
    var nonDiscountValue =
      parseFloat(editQuoteData.nonDiscountValue) - diffCost;
    var itemwiseCost = parseFloat(editQuoteData.itemwiseCost) - diffCost;
    var finalCost = totalDiscountPercentage * nonDiscountValue;
    costs.splice(index, 1);
    setEditQuoteData({
      ...editQuoteData,
      itemWiseSpecs: costs,
      nonDiscountValue,
      finalCost,
      itemwiseCost,
    });
  };
  const deletePackageItem = (packageKey, itemNo) => {
    var packageItems = packageSections[packageKey].items;
    packageItems.splice(itemNo, 1);
    setPackageSections({
      ...packageSections,
      [packageKey]: {
        ...packageSections[packageKey],
        items: packageItems,
      },
    });
  };
  /**
   * Gets sum of area of main items  ["1BHK", "2BHK", "3BHK"]
   */
  const getItemArea = (quoteData) => {
    var sum = 0;
    quoteData.floors.forEach((floor) => {
      floor.units.forEach((unit) => {
        if (normalAreas.includes(unit.type)) sum += parseFloat(unit.floorArea);
      });
    });
    return sum;
  };
  useEffect(() => {
    console.log(location.state);
    if (!location.state) {
      navigate("/generate-quote");
      return;
    }
    const quoteData = location.state;
    // console.log(quoteData);
    var floorString = quoteData.floors
      .map((rec, i) => {
        var floor = `${i}${getFloorSuffix(i)} Floor`;
        if (i === 0) floor = "Ground Floor";
        return floor;
      })
      .join(" + ");
    const totalArea = getItemArea(quoteData);
    const totalCost =
      parseFloat(totalArea) * parseFloat(quoteData.packages.packageRate);
    const itemWiseSpecifications = getItemWiseSpecifications(quoteData);
    var itemWiseCost = 0;
    itemWiseSpecifications.forEach((spec) => {
      itemWiseCost += parseFloat(spec.cost);
    });

    setEditQuoteData({
      ...editQuoteData,
      detailsDesc: generateDesc(quoteData),
      floorsTitle: floorString,
      itemWiseSpecs: itemWiseSpecifications,
      builtUpArea: totalArea,
      totalPackageCost: totalCost,
      totalSpecificationCost: totalCost,
      itemwiseCost: itemWiseCost,
      nonDiscountValue: itemWiseCost,
      finalCost: itemWiseCost,
      packageName: quoteData.packages.selectedPackage,
    });
    setPackageSections(
      getPackageData(quoteData.packages.selectedPackage, totalArea)
    );
  }, []);
  useEffect(() => {
    setPackageSections(
      getPackageData(editQuoteData.packageName, editQuoteData.builtUpArea)
    );
  }, [editQuoteData.builtUpArea, editQuoteData.packageName]);

  return (
    <div className="edit-quote">
      <div className="container">
        <div className="main-title">
          <h1>Edit quote</h1>
          <img src={underLine} alt="" />
        </div>
        <div className="body">
          <div className="section">
            <div className="title">
              <h2>Construction Requirement Details</h2>
            </div>
            <ReactQuill
              theme="snow"
              className="text-editor"
              modules={modules}
              formats={formats}
              placeholder=""
              value={editQuoteData.detailsDesc}
              onChange={handleProcedureContentChange}
            ></ReactQuill>
          </div>
          <div className="section">
            <div className="title">
              <h2>Specifications</h2>
            </div>
            <div className="body">
              <div className="cost">
                <p>
                  Total specification :{" "}
                  <span className="value">
                    Rs. {editQuoteData.totalSpecificationCost}
                  </span>
                </p>
              </div>
            </div>
            <div className="title">
              <h2>{editQuoteData.floorsTitle}</h2>
            </div>
            <div className="body specs-body">
              <div className="cost">
                <p>
                  Total Package Cost:{" "}
                  <span className="value">
                    Rs. {editQuoteData.totalPackageCost}
                  </span>
                </p>
              </div>
              <div className="field-container">
                <p>Builtup Area:</p>
                <TextField
                  required
                  id="built-up-area"
                  value={editQuoteData.builtUpArea}
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) => {
                    const cost =
                      parseInt(e.target.value) * editQuoteData.packageRate;

                    setEditQuoteData({
                      ...editQuoteData,
                      builtUpArea: parseFloat(e.target.value),
                      totalPackageCost: cost,
                    });
                  }}
                  type="number"
                  size="small"
                />
              </div>
              <div className="field-container">
                <p>Package Rate:</p>
                <TextField
                  required
                  id="package-cost"
                  value={editQuoteData.packageRate}
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) => {
                    const cost =
                      parseInt(e.target.value) * editQuoteData.builtUpArea;
                    setEditQuoteData({
                      ...editQuoteData,
                      packageRate: parseInt(e.target.value),
                      totalPackageCost: cost,
                    });
                  }}
                  type="number"
                  size="small"
                />
              </div>
            </div>
          </div>
          {Object.keys(packageSections).length > 0
            ? Object.keys(packageSections).map((key, i) => {
                if (key === "packageName" || key === "packageRate") return "";
                return (
                  <div className="section" key={`package-sectio-${i}`}>
                    <div className="title">
                      <h2>{packageSections[key].name}</h2>
                    </div>
                    <div className="body">
                      {packageSections[key].items.map((item, j) => {
                        return (
                          <div className="item" key={`package-item-${j}`}>
                            <div className="content">{item}</div>
                            <div
                              className="delete"
                              onClick={() => {
                                deletePackageItem(key, j);
                              }}
                            >
                              Delete
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })
            : "Unable to get package items. Please try again."}
          <div className="section">
            <div className="title">
              <h2>Item Wise Specification</h2>
            </div>
            <div className="cost">
              <p>
                Item wise cost :{" "}
                <span className="value">Rs. {editQuoteData.itemwiseCost}</span>
              </p>
            </div>
            <div className="cost">
              <p>
                Total Freebie :{" "}
                <span className="value">
                  Rs. {editQuoteData.totalFreebieCost}
                </span>
              </p>
            </div>
            <div className="body">
              <div className="table">
                <table>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Rate(Rs.)</th>
                      <th>Quantity</th>
                      <th>Cost(Rs.)</th>
                      <th>Is Freebie?</th>
                      <th>Unit Type</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {editQuoteData.itemWiseSpecs.map((rec, i) => {
                      return (
                        <tr key={`table-row-${i}`}>
                          <td>{rec.item}</td>
                          <td>{rec.rate}</td>
                          <td>{rec.quantity}</td>
                          <td>{rec.cost}</td>
                          <td>{rec.isFreebie ? "Yes" : "No"}</td>
                          <td>{rec.unitType}</td>
                          <td
                            className="delete"
                            onClick={() => {
                              deleteCost(i);
                            }}
                          >
                            Delete
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="controls specs">
            <div
              className="add-item"
              onClick={() => {
                setNewCostModel(true);
                setNewCostData({
                  item: "",
                  rate: 0,
                  quantity: 0,
                  cost: 0,
                  isFreebie: false,
                  unitType: "sqft",
                });
              }}
            >
              <button>Add item</button>
            </div>
            <div className="clear-all">
              <button>Clear All</button>
            </div>
          </div>
          <div className="section final">
            <div className="final-cost">
              <div className="title">Total discount percentage:</div>
              <div className="value">
                <TextField
                  size="small"
                  value={editQuoteData.totalDiscountPercentage}
                  onChange={(e) => {
                    const percent = parseFloat(e.target.value) || 0;
                    const discountAmount =
                      (percent / 100) * editQuoteData.nonDiscountValue || 0;
                    setEditQuoteData({
                      ...editQuoteData,
                      totalDiscountPercentage: percent,
                      finalCost: (
                        editQuoteData.nonDiscountValue - discountAmount
                      ).toFixed(2),
                    });
                  }}
                  type="number"
                />
              </div>
            </div>
            <div className="final-cost">
              <div className="title">Total Non Discounted Project Value :</div>
              <div className="value">
                <TextField
                  size="small"
                  value={editQuoteData.nonDiscountValue}
                  disabled
                />
              </div>
            </div>
            <div className="final-cost">
              <div className="title">Total cost:</div>
              <div className="value">
                <TextField
                  size="small"
                  disabled
                  value={editQuoteData.finalCost}
                />
              </div>
            </div>
          </div>
          <div className="field-container" style={{ marginBottom: "1rem" }}>
            <p>Name of the customer:</p>
            <TextField
              required
              id="package-cost"
              value={editQuoteData.name}
              onChange={(e) => {
                setEditQuoteData({
                  ...editQuoteData,
                  name: e.target.value,
                });
              }}
              type="text"
              size="small"
            />
          </div>
          <div className="controls">
            <div className="submit-quote">
              <button
                onClick={() => {
                  setPdfPreviewModal(true);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {pdfPreviewModal && (
        <div
          className="pdf-preview"
          onClick={(e) => {
            if (
              !document
                .querySelector(".pdf-preview .wrapper .container")
                .contains(e.target)
            ) {
              setPdfPreviewModal(false);
            }
          }}
        >
          <div className="wrapper">
            <div className="container">
              <PDFViewer width="100%">
                <Document className="final-pdf" style={styles.body}>
                  <Page
                    style={{
                      ...styles.page,
                      paddingTop: 0,
                      paddingHorizontal: 0,
                    }}
                  >
                    <Image
                      style={{
                        ...styles.waterMark,
                        paddingHorizontal: 35,
                        left: "25%",
                      }}
                      src={waterMark}
                    />

                    <View style={styles.header}>
                      <Image style={styles.image} src={pdfLogo} />
                      <Text style={styles.text}>
                        Specification for {editQuoteData.name}
                      </Text>
                      <Text style={styles.text}>
                        Total Cost : Rs. {editQuoteData.finalCost}
                      </Text>
                      <Text style={{ ...styles.text, fontSize: 10 }}>
                        (including GST)
                      </Text>
                    </View>
                    <View style={{ paddingHorizontal: 35 }}>
                      <Text style={styles.title}>
                        Construction Requirement Detail
                      </Text>
                      <Text style={styles.text}>
                        {editQuoteData.detailsDesc
                          .replaceAll("\n", "")
                          .replaceAll("<br />", "\n")}
                      </Text>
                    </View>
                    <View style={styles.footer} fixed>
                      <Text>
                        @ www.buildgenix.com : This is a system generated
                        quotation
                      </Text>
                      <Text
                        render={({ pageNumber, totalPages }) =>
                          `Page ${pageNumber} of ${totalPages}`
                        }
                        fixed
                      />
                    </View>
                  </Page>
                  <Page style={styles.page}>
                    <Image fixed style={styles.waterMark} src={waterMark} />
                    <View>
                      <Text style={styles.title}>Cost Breakup</Text>
                      <Table
                        data={{
                          column: [
                            "item",
                            "rate",
                            "quantity",
                            "cost",
                            "isFreebie",
                            "unitType",
                          ],
                          data: editQuoteData.itemWiseSpecs,
                        }}
                      />
                    </View>
                    <View style={styles.footer} fixed>
                      <Text>
                        @ www.buildgenix.com : This is a system generated
                        quotation
                      </Text>
                      <Text
                        render={({ pageNumber, totalPages }) =>
                          `Page ${pageNumber} of ${totalPages}`
                        }
                        fixed
                      />
                    </View>
                  </Page>
                  <Page style={styles.page}>
                    <Image fixed style={styles.waterMark} src={waterMark} />
                    <View>
                      <Text style={styles.title}>
                        {editQuoteData.floorsTitle}
                      </Text>
                      <Text style={styles.titleDesc}>
                        Package Name : {packageSections.packageName}
                      </Text>
                      <Text style={styles.titleDesc}>
                        Package Rate : Rs. {editQuoteData.packageRate} per sqft
                      </Text>
                      <Text style={styles.titleDesc}>
                        Area : {editQuoteData.builtUpArea} sqft
                      </Text>
                      <Text style={styles.titleDesc}>
                        Tortal Builtup area : {editQuoteData.builtUpArea} sqft
                      </Text>
                    </View>
                    <View>
                      {Object.keys(packageSections).map((key, i) => {
                        const obj = packageSections[key];
                        if (key === "packageName" || key === "packageRate")
                          return "";
                        return (
                          <View style={{ marginTop: 20 }}>
                            <Text
                              style={{ ...styles.titleDesc, color: "#F57500" }}
                            >
                              {obj.name}
                            </Text>
                            <View style={{ marginTop: 8 }}>
                              {obj.items.map((item) => {
                                return <Text style={styles.text}>{item}</Text>;
                              })}
                            </View>
                          </View>
                        );
                      })}
                    </View>
                    <View style={styles.footer} fixed>
                      <Text>
                        @ www.buildgenix.com : This is a system generated
                        quotation
                      </Text>
                      <Text
                        render={({ pageNumber, totalPages }) =>
                          `Page ${pageNumber} of ${totalPages}`
                        }
                        fixed
                      />
                    </View>
                  </Page>
                </Document>
              </PDFViewer>
            </div>
          </div>
        </div>
      )}
      {newCostModel && (
        <div
          className="new-cost-model"
          onClick={(e) => {
            if (
              !document
                .querySelector(".new-cost-model .wrapper .container")
                .contains(e.target)
            ) {
              setNewCostModel(false);
            }
          }}
        >
          <div className="wrapper">
            <div className="container">
              <div
                className="close-btn"
                onClick={(e) => {
                  setNewCostModel(false);
                }}
              >
                X
              </div>
              <div className="field-container">
                <p>Item : </p>
                <TextField
                  value={newCostData.item}
                  onChange={(e) => {
                    setNewCostData({
                      ...newCostData,
                      item: e.target.value,
                    });
                  }}
                  size="small"
                />
              </div>
              <div className="field-container">
                <p>Rate : </p>
                <TextField
                  value={newCostData.rate}
                  onChange={(e) => {
                    setNewCostData({
                      ...newCostData,
                      rate: parseInt(e.target.value),
                      cost: parseFloat(e.target.value) * newCostData.quantity,
                    });
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  type="number"
                  size="small"
                />
              </div>
              <div className="field-container">
                <p>Quantity : </p>
                <TextField
                  value={newCostData.quantity}
                  onChange={(e) => {
                    setNewCostData({
                      ...newCostData,
                      quantity: parseInt(e.target.value),
                      cost: parseFloat(e.target.value) * newCostData.rate,
                    });
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  type="number"
                  size="small"
                />
              </div>
              <div className="field-container">
                <p>Cost : </p>
                <TextField
                  disabled
                  value={newCostData.cost}
                  type="number"
                  size="small"
                />
              </div>
              <div className="field-container">
                <p>Unit Type : </p>
                <TextField
                  value={newCostData.unitType}
                  onChange={(e) => {
                    setNewCostData({
                      ...newCostData,
                      unitType: e.target.value,
                    });
                  }}
                  size="small"
                />
              </div>
              <div className="field-container">
                <p>Is Freebie : </p>
                <Checkbox
                  value={newCostData.isFreebie}
                  onChange={(e) => {
                    setNewCostData({
                      ...newCostData,
                      isFreebie: e.target.checked,
                    });
                  }}
                />
              </div>
              <div className="controls">
                <button
                  className="add-cost"
                  onClick={(e) => {
                    setNewCostModel(false);
                    const totalItemCost =
                      editQuoteData.itemwiseCost + parseFloat(newCostData.cost);
                    const discount =
                      (editQuoteData.totalDiscountPercentage / 100) *
                      totalItemCost;
                    setEditQuoteData({
                      ...editQuoteData,
                      itemwiseCost: totalItemCost,
                      nonDiscountValue: totalItemCost,
                      itemWiseSpecs: [
                        ...editQuoteData.itemWiseSpecs,
                        newCostData,
                      ],
                      finalCost: totalItemCost - discount,
                    });
                  }}
                >
                  Add Cost
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
function Table({ data }) {
  const styles = StyleSheet.create({
    rowView: {
      display: "flex",
      flexDirection: "row",
      border: "1px solid #000",
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: "center",
    },
  });
  return (
    <>
      {data && (
        <>
          <View style={{ ...styles.rowView, marginTop: 10 }}>
            {data["column"].map((c, i) => (
              <Text
                style={{
                  width: `${
                    i === 0 ? "30" : `${100 / data["column"].length}`
                  }%`,
                  fontSize: 14,
                }}
              >
                {c}
              </Text>
            ))}
          </View>
          {data["data"].map((rowData) => (
            <>
              <View style={styles.rowView}>
                {data["column"].map((c, i) => (
                  <Text
                    style={{
                      width: `${
                        i === 0 ? "30" : `${100 / data["column"].length}`
                      }%`,
                      fontSize: 10,
                    }}
                  >
                    {c === "isFreebie"
                      ? `${rowData[c] ? "yes" : "no"}`
                      : rowData[c]}
                  </Text>
                ))}
              </View>
            </>
          ))}
        </>
      )}
    </>
  );
}
