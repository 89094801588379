import "./about.scss";
import underLine from "../../../assets/icons/under-line-paint.svg";
import aboutImg from "../../../assets/images/about.png";
import missionImg from "../../../assets/images/mission-img.png";
import dummyPerson from "../../../assets/images/guild-dummy.png";
import william from '../../../assets/images/william.png';
import sophia from '../../../assets/images/sophia.png';
import sophia2 from '../../../assets/images/sophia-2.png';
import ivana from '../../../assets/images/ivana.png';
import ivana2 from '../../../assets/images/ivana-2.png';

export default function About() {
  const guild = [
    {
      name: "Emma Roberts",
      designation: "UI Designer",
      img: dummyPerson,
      desc: "Artist is a term applied to a person who engages in an activity deemed to be an art.",
    },
    {
      name: "William Pearce",
      designation: "Boss",
      img: william,
      desc: "Artist is a term applied to a person who engages in an activity deemed to be an art.",
    },
    {
      name: "Ivana Flow",
      designation: "UX Designer",
      img: ivana,
      desc: "Artist is a term applied to a person who engages in an activity deemed to be an art.",
    },
    {
      name: "Sophia Garcia",
      designation: "Full Stack Developer",
      img: sophia,
      desc: "Artist is a term applied to a person who engages in an activity deemed to be an art.",
    },
    {
      name: "Ivana Flow",
      designation: "UI Designer",
      img: ivana2,
      desc: "Artist is a term applied to a person who engages in an activity deemed to be an art.",
    },
    {
      name: "Sophia Gracia",
      designation: "Js Developer",
      img: sophia2,
      desc: "Artist is a term applied to a person who engages in an activity deemed to be an art.",
    },
  ];
  return (
    <div className="about">
      <div className="title mobile">
            <h1>About Us</h1>
            <img src={underLine} alt="" />
          </div>
      <div className="about-section">
        <div className="col">
          <div className="title desktop">
            <h1>About Us</h1>
            <img src={underLine} alt="" />
          </div>
          <div className="desc">
            <p>
              BuildGenix, is a cutting-edge construction company that leverages
              the power of technology to revolutionize the industry. With a
              passion for innovation and a commitment to excellence, we are
              dedicated to delivering exceptional results for our clients.
            </p>
            <p>
              At BuildGenix, we also understand that successful construction
              projects require effective collaboration and communication. That's
              why we leverage digital project management platforms and
              cloud-based tools to foster seamless collaboration between our
              team, clients, and stakeholders. This ensures transparency, timely
              updates, and efficient decision-making throughout the project
              lifecycle.
            </p>
          </div>
        </div>
        <div className="col">
          <div className="image">
            <img src={aboutImg} alt="" />
          </div>
        </div>
      </div>
      <div className="mission">
        <div className="title">
          <h1>Our Mission</h1>
          <img src={underLine} alt="" />
        </div>
        <div className="desc">
          <p>
            Our mission is not only to construct remarkable structures but also
            to leave a positive impact on society. We actively engage with local
            communities and support initiatives that enhance social well-being
            and economic development. By actively participating in sustainable
            community projects, we strive to create a positive legacy that
            extends beyond the completion of our construction projects.
          </p>
          <p>
            We are passionate about pushing boundaries, embracing change, and
            shaping the future of construction. With our technology-driven
            approach and unwavering commitment to sustainability, we aim to
            inspire and lead the industry towards a more sustainable and
            prosperous future.
          </p>
        </div>
        <div className="mission-img">
          <img src={missionImg} alt="" />
        </div>
      </div>
      <div className="guild">
        <div className="title">
          <h1>The Guild</h1>
          <img src={underLine} alt="" />
        </div>
        <div className="container">
          {guild.map((member, i) => {
            return (
              <div className="team-card">
                <div className="team-person">
                  <img src={member.img} alt="" />
                </div>
                <div className="detail">
                  <p className="name">{member.name}</p>
                  <p className="designation">{member.designation}</p>
                  <p className="desc">{member.desc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
