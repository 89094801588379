import "./blog.scss";
import underLineImage from "../../../assets/icons/under-line-paint.svg";
import blogImage1 from "../../../assets/images/blog-images/blog-1.png";
import blogImage2 from "../../../assets/images/blog-images/blog-2.png";
import blogImage3 from "../../../assets/images/blog-images/blog-3.png";
import blogImage4 from "../../../assets/images/blog-images/blog-4.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import blogPosts from './blogs.json'
export default function Blog() {
  const [blogs,setBlogs] = useState(blogPosts);
 
  return (
    <div className="blog">
      <div className="title">
        <h1>Latest from BuildGenix</h1>
        <img src={underLineImage} alt="" />
      </div>
      <div className="body">
        <div className="blog-cards">
          {blogs.map((rec, i) => {
            return (
              <div className="blog-card" key={`blog-${rec.id}`}>
                <div className="card-image">
                  <img src={blogImage1} alt="" />
                </div>
                <div className="card-body">
                  <div className="card-title">
                    <h3>{rec.title}</h3>
                  </div>
                  <div className="card-desc">
                    <p>{rec.desc}</p>
                  </div>
                  <div className="read-more">
                    <Link to="/blog-post" state={rec}>
                      Read more...
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
