var packages = {
  "Elite": {
    packageName: "Elite Package for House Construction in Hyderabad",
    packageRate: "2240.0",
    designDrawings: {
      name: "Design & Drawings",
      items: [
        "2D Floor Plan: We will provide a comprehensive 2D representation of the floor layout - 9 iterations.",
        "3D Elevation: A realistic 3D representation of the elevation.",
        "Structural Design: Our team will prepare the necessary structural designs at 3kg/sft with respect to the approved floor plan.",
        "Electrical Drawings: Basic electrical layout drawing is included and detailed electrical drawings like load calculations will be charged extra.",
        "Plumbing Drawings: Basic plumbing layout is included",
      ],
      note: "All designs will be provided in a non-editable PDF format.",
    },
    structure: {
      name: "Structure",
      items: [
        "Aggregates: We will utilize 20mm and 40mm aggregates in the construction process.",
        "Bricks: Red will be used, with 9 inches thickness for exterior walls and 4 inches for interior walls.",
        "M-sand: For blockwork/brickwork and River sand for plastering and M-sand will be used for flooring",
        "RCC Design Mix: The reinforced concrete (RCC) mix will be determined based on the recommendations of the structural designer and M-sand will be used in concrete.",
        "Dr.Fixit/Fosroc Waterproofing: To ensure proper water resistance, we will incorporate Dr.Fixit Waterproofing products",
        "Ceiling Height: The finished floor level to finished floor level ceiling height will be 10 feet.",
      ],
    },
    foundation: {
      name: "Foundation",
      items: [
        "The foundation will be constructed as per the structural drawings.",
        "The standard foundation depth will be 6 feet. However, additional foundation work may be necessary depending on the soil quality discovered during excavation. Any additional depth beyond 6 feet will incur an extra cost. Please note that the package rate covers the foundation cost for a general case. The actual cost will be determined based on the SBC report and finalized structural designs.",
      ],
    },
    others: {
      name: "Other Construction Details",
      items: [
        "The finished floor level on the ground floor will be raised up to 2 feet above the existing ground level.",
        "Outer and partition walls will be built using red bricks.",
        "A 100mm thick PCC (Plain Cement Concrete) layer will be provided below the ground floor flooring.",
        "Internal plastering will be done using a 1:5 cement mortar mix. External and ceiling plastering will use a 1:5 and 1:4 cement mortar mix, respectively. The plaster thickness will be 12mm applied in 2 coats for internal and 15mm applied in two coats for external.",
        "Chicken mesh will be placed near beam, column, and masonry wall junctions for added strength.",
        "Fosroc or an equivalent brand's waterproofing compound will be applied to the internal and external plastering.",
        "Sika Antisol or an equivalent brand's curing compound will be used for internal and external plastering.",
        "Terrace slab, all washrooms, and balconies will undergo waterproofing using Fosroc Brushbond or equivalent brands.",
        "Internal plaster, external plaster, and mortar mix used for fixing washroom tiles will incorporate a waterproofing admixture from Fosroc or an equivalent brand",
        "Steel: JSW Neo or Tata will be the preferred steel brands.",
        "ent: We will use Ultratech Super and ACC cement brands.",
      ],
    },
    kitchen: {
      name: "Kitchen",
      items: [
        "Ceramic Wall Tiles: The kitchen walls will be tiled up to 2 feet above the kitchen slab. The cost of the tiles will be up to Rs. 80 per square foot.",
        "Main Sink Faucet: We offer a selection of reputable brands such as Jaquar, Parryware, Hindware, or equivalent, with a maximum price of Rs. 4000.",
        "Other Faucets & Accessories: Basic Jaquar, Parryware, Hindware fittings will be provided for other faucets and accessories.",
        "Kitchen Sink: A stainless steel single sink or a granite finish sink worth Rs. 9000 (Brands: Futura, Carysil) will be installed.",
        "Water Purification Provision: Provision will be made for a water purification inlet near the kitchen sink.",
        "Washing Machine Provision: In the utility/dry balcony area, provision will be made for a washing machine inlet and outlet.",
        "Water Inlet and Outlet: The utility area will be equipped with a water inlet and outlet. Taps will be provided for both the kitchen and utility area.",
        "60sft of the kitchen area is included in the package built-up area 500 sq ft. Additional kitchen area will be charged on a pro-rata basis",
        "Exhaust Fan Provision: If desired, provision will be made for an exhaust fan in the kitchen.",
        "Kitchen Countertop: The kitchen countertop will be made of granite with a thickness of 16-18mm. The width will be 2 feet, with a cost of up to Rs. 200 per sqft.",
      ],
    },
    bathroom: {
      name: "Bathroom",
      items: [
        "Bathroom Ceramic Wall Tiles: The bathroom walls will be tiled up to a height of 7 feet. The cost of the tiles will be up to Rs. 80 per square foot.",
        "Sanitary Ware & CP Fittings: We offer a selection of reputable brands such as Kohler/Jaquar/Hindware/Parryware with a budget of up to Rs. 70,000 for 1000 sq ft of package built up area, and prorated thereafter.",
        "CPVC Pipe: High-quality Ashirwad/Astral pipes, or equivalent, will be used for the plumbing system.",
        "Bathroom Accessories: The bathroom will be equipped with EWC (European Water Closet), health faucet, wash basin with accessories, 2-in-1 wall mixer, and overhead shower.",
        "Bathroom Doors: WPC doors will be used for the bathroom entrance.",
        "Spacer Joints and Grouting: Spacer joints will be provided in the floor tiles and wall dados, filled with polymeric cement grout from brands like Roff, Weber, or equivalent.",
        "External Pipes: External pipes will be fixed on raised clamps.",
        "Exhaust Fan Provision: If desired, provision will be made for an exhaust fan in the bathroom.",
        "Rainwater Discharge Pipes: At least one 6-inch rainwater discharge pipes will be provided per 1200 sqft of plot area, with pro-rata thereafter.",
        "Additional Washrooms: One washroom is included in the package per 500 sqft of built-up area. Additional washrooms will be charged on a pro-rata basis.",
        "Sewer Line Connection: The package includes a sewer line connection to the main drain line up to 25 feet from the building.",
        "Bathroom Flooring: Anti-skid tiles with a value of up to Rs. 80 per sqft will  be used for the bathroom flooring.",
      ],
    },
    mainDoor: {
      name: "Main Door",
      items: [
        "The main door will be made of Teak wood with a thickness of 38mm. It will come with a teak frame measuring 5 inches by 3.5 inches and the main door size will be of 3’6’’x7’.",
        "The main door is worth up to Rs. 40,000, including fixtures. Premium quality locks from Europa or Godrej, worth Rs. 2,000, will be used. The door will be equipped with 2 brass tower bolts and 3 brass hinges.",
      ],
    },
    internalDoors: {
      name: "Internal Doors",
      items: [
        "The internal doors will be hardwood paneled doors. Each door, along with fixtures, is worth up to Rs. 11,000. The door frames will be made of Sal Wood measuring 4 inches by 3 inches.",
        "Each internal door will have 1 SS tower bolt, 3 MS hinges, and a lock with a key. The cost of each internal lock is worth Rs. 1,500.",
      ],
    },
    windows: {
      name: "Windows",
      items: [
        "The windows will be made of UPVC, and they will have glass and mesh shutters. They will be 3-track windows with 1 mesh.",
        "The cost of windows, including frames and fixtures, is up to Rs. 600 per square foot.",
      ],
    },
    poojaRoom: {
      name: "Pooja Room Door",
      items: [
        "The Pooja Room door will be made of Indian Teak along with a teak frame measuring 5 inches by 2.5 inches. It is worth Rs. 30,000.",
      ],
    },
    washRoomDoor: {
      name: "Washroom Doors",
      items: [
        "The washroom doors will be flush doors and waterproof. The cost of each door, including frames and fixtures, is worth Rs. 8,000.",
      ],
    },
    doorWindows: {
      name: "Door and Window Details",
      items: [
        "The main door size will be 4'0`` x 7'0``, room doors will be 3'0``` x 7'0``, and bathroom doors will be 2'6`` x 7'0``.",
        "The main door frame size will be 0'3`` x 0'5``, while the internal room and bathroom frame size will be [2.5`` x 4``].",
      ],
    },
    additionalSpecifications: {
      name: "Additional Specifications",
      items: [
        "MS grills with a maximum spacing of 125mm and 10mm thick rods will be installed for windows.",
        "Each main door and room door will have a wall-mounted magnetic door stopper and a front door stopper.",
        "One Pooja Room will be provided for every 2000 sq ft of built-up area.",
        "The timber used for the main door and Pooja Room door will be Teak wood, polished and varnished with two coats.",
        "All timber used for windows and doors will be well-seasoned, free from defects, and accurately fitted. Sal woodworks will be painted with two coats of paint.",
        "The total area of window and ventilator openings, including frames, will be 10% of the package built-up area.",
        "Window Chajja will have a projection of up to 1 foot outward and an additional 6 inches on each side of the window.",
      ],
    },
    painting: {
      name: "Painting",
      items: [
        "Interior Painting: We will use JK Putty along with Apcolite Premium Emulsion or an equivalent product.",
        "Exterior Painting: For the exterior walls, we will apply Asian Primer followed by Apex Exterior Emulsion Paint or an equivalent product.",
        "Enamel paint will be used for all the MS grill works. Prior to painting, an anti-corrosion coat will be applied to all MS steel surfaces.",
        "The external walls will be painted with one coat of primer and two coats of paint.",
        "For the internal walls and ceiling, two coats of wall putty will be provided.",
      ],
    },
    flooring: {
      name: "Flooring",
      items: [
        "Living and Dining Flooring: You can choose from tiles, granite, or marble with a value of up to Rs. 140 per sqft.",
        "Rooms and Kitchen Flooring: You can choose from tiles, granite, or marble with a value of up to Rs. 120 per sqft.",
        "Balcony and Open Areas Flooring: Anti-skid tiles with a value of up to Rs. 80 per sqft will be used.",
        "Staircase Flooring: Granite with a value of up to Rs. 110 per sqft will be used.",
        "Parking Tiles: Anti-skid tiles with a value of up to Rs. 70 per sqft will be used.",
        "The ceramic tiles used for kitchen and bathroom walls will be free from cracks, spots, grazes, chipped edges and corners. The thickness will be as specified, but not less than 6 mm.",
        "If granite is used, it will be approved by the engineer and will have a minimum thickness of 16-18 mm.",
        "Wall tiles will be fixed with tile adhesive, and the joints will be cleaned thoroughly and grouted with site-made grout.",
        "Polymeric cement grout of Roff/ Weber/ equivalent brands will be used for grouting in balconies and terraces (if opted).",
        "Plinth protection up to two feet wide is included in the package.",
        "Flooring, dado, and skirting will be level and plumb.",
        "Skirting will be made of tiles or granite with a minimum height of 75mm.",
        "Labor charges for cutting, laying, polishing, nosing, and chamfering of granite will be extra.",
        "Labor charges for laying tiles larger than 2ft x 4ft will be extra.",
      ],
    },
    electrical: {
      name: "Electrical",
      items: [
        "Wires: We will use fireproof wires by Finolex, ensuring safety.",
        "Switches & Sockets: GM Modular or Legrand switches and sockets will be installed, ensuring quality.",
        "Provision for television cable connection will be provided for your convenience.",
        "Corridors, passages, porticos, parking areas, utility spaces, terraces, stairs, balconies, and other built-up areas will be equipped with light points and plug points at suitable locations. Five such points will be provided per 1000 sqft.",
        "Wiring for UPS is not included in the package.",
        "An earthing pit will be provided for one dwelling capacity per 1000 sqft of the package built-up area, ensuring electrical safety.",
        "Bathrooms will be equipped with two light points, one geyser point, and one plug point near the sink.",
        "Each room will have three light points, one fan point, and four plug points near the bedside table, TV, study/dressing area, and air conditioner.",
        "The living room, dining room, and family hall will each have one or two fan points, four light points, and four plug points.",
        "The kitchen will have two light points and five plug points for the refrigerator, mixer, oven, water purifier, and platform appliances.",
        "An MCB board (Anchor, Finolex, or equivalent brand) will be provided for circuit protection.",
        "Each house will be provided with one calling bell.",
        "If required, 2-way switches can be provided for rooms, living areas, and stairs.",
      ],
    },
    miscellaneous: {
      name: "Miscellaneous",
      items: [
        "Overhead Tank: Sintex Triple Layered 2000 liters will be provided.",
        "Underground Sump: A 7000-liter capacity underground sump will be included.",
        "Staircase Railing: Stainless Steel (SS) railing will be installed for the staircase.",
        "Window Grills: Windows will have MS grills with enamel paint, customized as per your requirements at a cost of 130 per sqft.",
        "Main Gate and Post: The main gate and post are not included in the package. The gate will be made of mild steel, adhering to approved design standards. It will have a minimum of two hinges, one top latch, and one bottom latch.",
        "Compound Wall: If provided, the compound wall will be constructed using cement block masonry, with a height of 5 feet and a thickness of 115mm. Buttress/columns will be provided every 10 feet. The compound wall will be plastered and painted as per specifications.",
        "Weatherproof Course: A weatherproof course will be provided at the terrace using waterproofing agents such as Dr. Fixit or equivalent brand. PPC cement will be used for structural and non-structural works at the site.",
        "Rainwater Harvesting: As per GHMC/HMDA norms, rainwater harvesting can be provided at an extra cost. However, it is not included in the package.",
        "Front Elevation: The front elevation of the building will include basic plastering and external painting without cladding, grooves, cuts, projections, or any other purely aesthetic elements.",
        "GST Inclusion: All rates are inclusive of GST. We do not charge GST separately.",
        "Parapet Wall: A parapet wall with a height of 3’6’’ feet and thickness of 150mm will be provided. Buttress/columns will be provided every 10 feet.",
      ],
      note: "Please note that there are additional specifications and costs for various aspects of the project. These include warranties, excavation costs for rocky terrains, individual foundation costs, platform costs for the overhead tank, and more. We encourage you to review the complete list for a comprehensive understanding.",
    },
    customizationCosts: {
      name: "Customization Costs",
      items: [
        "SSM below the plinth is not included in the standard package.",
        "The cost of an external drain cover (100mm) using Natural Stone is Rs 300 per sq.ft.",
        "Additional capacity for the RCC underground sump is available at Rs 18 per liter.",
        "Additional capacity for the overhead tank can be provided at Rs 8 per liter.",
        "In the case of an external staircase, headroom is not included in the standard package. The headroom will be charged at the same rate as the package built-up area.",
        "Additional capacity for the blockwork underground sump is priced at Rs 13 per liter.",
        "Lofts inside rooms and the kitchen are not part of the standard package. However, they can be provided at an additional cost of Rs 280 per sq.ft.",
        "For Italian Marble laying cost is extra as per market standards, chemical treatment will cost Rs 20 per sq.ft.",
        "The additional cost of laying Indian marble will be charged as per market standards.",
        "The additional cost of laying granite is Rs 18/sq.ft. If buffing or chamfering/nosing is required, the charges will be Rs 30 per running foot.",
        "The cost of a blockwork septic tank is Rs 13/litre, while a concrete septic tank is priced at Rs 18/litre.",
      ],
    },
  },
};

/**
 *
 * @param {*} area - total builtup area of the project before the items section
 */
export const getPackageData = (packageName, area) => {
  const builtupArea = parseFloat(area).toFixed(2);
  /**Items based on buitup area */
  //bathroom
  var bathItem1 = `CP Fittings and Sanitary Ware Value: The value of CP fittings and sanitary ware is calculated at Rs. 70.0 x ${builtupArea} = Rs. ${(
    70 * builtupArea
  ).toFixed(2)}`;
  var currentPackage = packages[packageName];
  try {
    currentPackage = {
      ...currentPackage,
      bathroom: {
        ...currentPackage["bathroom"],
        items: [...currentPackage["bathroom"].items, bathItem1],
      },
    };
    return currentPackage;
  } catch (err) {
    return {};
  }
};
