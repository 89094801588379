import { useState } from "react";
import "./login.scss";
import underLine from "../../../assets/icons/under-line-paint.svg";
import mailIcon from "../../../assets/icons/mail-icon.svg";
import mapIcon from "../../../assets/icons/map-icon.svg";
import callIcon from "../../../assets/icons/call-icon.svg";
import twIcon from "../../../assets/icons/tw.svg";
import inIcon from "../../../assets/icons/in.svg";
import fbIcon from "../../../assets/icons/fb.svg";
import igIcon from "../../../assets/icons/ig.svg";
import titleBg from "../../../assets/icons/title-bg-rect.svg";
import loginBg from "../../../assets/images/login-bg.svg"
export default function Login() {
  const [formData, setFormData] = useState({
    mail: "",
    password: "",
  });
  const [formError, setFormError] = useState(null);
  const submitForm = (e) => {
    console.log("form data");
  };
  const socialIcons = [
    {
      name: "facebook",
      img: fbIcon,
      link: "",
    },
    {
      name: "instagram",
      img: igIcon,
      link: "",
    },
    {
      name: "twitter",
      img: twIcon,
      link: "",
    },
    {
      name: "linkedin",
      img: inIcon,
      link: "",
    },
  ];
  return (
    <div className="login">
      <div className="body">
        <div className="col">
          <div className="form">
            <div className="form-title">
              <h2>Login</h2>
              <p>(For Existing Customers Only)</p>
            </div>
            <div className="form-body">
              <div className="field-container">
                <label htmlFor="name">E-mail</label>
                <input
                  type="email"
                  name=""
                  id="name"
                  value={formData.name}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      mail: e.target.value,
                    });
                  }}
                  placeholder="email"
                />
              </div>
              <div className="field-container">
                <label htmlFor="phone">Password</label>
                <input
                  type="password"
                  name=""
                  id="phone"
                  value={formData.phone}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      password: e.target.value,
                    });
                  }}
                  placeholder="Enter your password"
                />
              </div>
              

              {formError ? (
                <div className="error">
                  <p>{`*${formError}`}</p>
                </div>
              ) : (
                ""
              )}
              <div className="submit">
                <button onClick={submitForm}>Submit</button>
              </div>
              <div className="forgot-password">
                <a href="/">Forgot password?</a>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <img src={loginBg} alt="" />
        </div>
      </div>
    </div>
  );
}
